import * as React from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import { Facebook, Instagram, Twitter } from "@mui/icons-material";
import { Box } from "@mui/material";

export default function Footer() {
  return (
    <Box
      component="footer"
      sx={{
        backgroundColor: (theme) =>
            theme.palette.mode === "light"
              ? theme.palette.grey[200]
              : theme.palette.grey[800],
        p: 6,
        marginTop: 'auto',
        bottom: 0
      }}
    >
      <div data-aos="fade-up">

      <Container maxWidth="lg">
        <Grid container spacing={5}>
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" color="#7A1B1C" gutterBottom>
              About Us
            </Typography>
            <Typography variant="body2" color="text.secondary">
              At Infinity Adda, we're more than just an e-commerce website – we're a hub for electronic and electric components enthusiasts.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" color="#7A1B1C" gutterBottom>
              Contact Us
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Email: shivammishracreator2@gmail.com
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Phone: +91 7879070809
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Technical feedbacks: shivanshdubey232@gmail.com
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" color="#7A1B1C" gutterBottom>
              Follow Us
            </Typography>
            <Link href="https://www.facebook.com/profile.php?id=61551098198473" color="#7A1B1C">
              <Facebook />
            </Link>
            <Link
              href="https://www.instagram.com/infinityadda"
              color="#7A1B1C"
              sx={{ pl: 1, pr: 1 }}
            >
              <Instagram />
            </Link>
            <Link href="https://twitter.com/INFINITYADDA" color="#7A1B1C">
              <Twitter />
            </Link>
          </Grid>
        </Grid>
        <Box mt={5}>
          <Typography variant="body2" color="text.secondary" align="center">
            {"Copyright © "}
            <Link color="#7A1B1C" href="https://infinityadda.com/">
              infinityadda.com
            </Link>{" "}
            {new Date().getFullYear()}
            {"."}
          </Typography>
        </Box>
      </Container>
      </div>
    </Box>
  );
}