import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  Card,
  CardContent,
  Typography,
  IconButton,
  Button,
  Input,
  Grid,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

const CartItem = ({ item, onQuantityChange, onRemove }) => {
  const [localQuantity, setLocalQuantity] = useState(item.quantity);
  const handleQuantityChange = (event) => {
    const newQuantity = parseInt(event.target.value, 10);
    setLocalQuantity(newQuantity);
    onQuantityChange(item.id, newQuantity);
  };

  return (
    <Card sx={{ marginBottom: 2 }}>
      <CardContent>
        <Grid container justifyContent="space-between" alignItems="center">
          {item.product ? (
            <>
              <Typography component="div" sx={{ fontWeight: 'bold' }}>
                {item.product.data().productName}
              </Typography>
              <Grid
                xs={12}
                justify="space-between"
                style={{
                  backgroundImage: `url(${item.product.data().imageURLs[0]})`,
                  height: '250px',
                  width: '250px',
                  marginTop: 20,
                  backgroundSize: 'cover',
                }}
              ></Grid>
              <br />
              <Typography variant="body2" component="div">
                Order Id:
                <span style={{ color: '#7A1B1C' }}>
                  &nbsp;
                  {item.id}
                </span>
              </Typography>
              <br />
              <Typography
                component="div"
                style={{ color: '#7A1B1C', fontWeight: 'bold' }}
              >
                Rs. {item.product.data().price}/- &nbsp;
              </Typography>
            </>
          ) : (
            <>
              <Typography component="div" sx={{ fontWeight: 'bold' }}>
                {item.productName} (Product not listed)
              </Typography>
              <Typography variant="body2" component="div">
                Order Id:
                <span style={{ color: '#7A1B1C' }}>
                  &nbsp;
                  {item.id}
                </span>
              </Typography>
              <Typography
                component="div"
                style={{ color: '#7A1B1C', fontWeight: 'bold' }}
              >
                Rs. 0/- &nbsp;
              </Typography>
            </>
          )}

          <IconButton color="error" onClick={() => onRemove(item.id)}>
            <DeleteIcon />
          </IconButton>
        </Grid>
        <Typography variant="body1" color="textSecondary">
          Quantity:
          <Input
            type="number"
            value={localQuantity}
            onChange={handleQuantityChange}
            inputProps={{ min: 1 }}
          />
        </Typography>
        <br />
        {item.product && (
          <Link
            to={`/products/${item.productId}`}
            style={{ textDecoration: 'none', width: '100%' }}
          >
            <Button
              variant="contained"
              color="primary"
              sx={{ marginTop: '10px', width: '100%', color: 'white' }}
            >
              View Details
            </Button>
          </Link>
        )}
      </CardContent>
    </Card>
  );
};

export default CartItem;
