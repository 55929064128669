import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Typography, Grid, Modal, Box, Paper } from '@mui/material';

function OrderItemDetails({ order }) {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <br />
      <div>
        <Link to={`/products/${order.productId}`} style={{ textDecoration: "none" }}>
          <Button variant="outlined" color="primary">
            View Product
          </Button>
        </Link>
        <Button variant="outlined" color="primary" onClick={handleOpen}>
          View Details
        </Button>

        {/* Modal for displaying details */}
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={{ ...style, width: 400 }}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Details
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              { order.productName }
              <br />
              Quantity: {order.quantity}
              <br />
              Ordered On: {order.createdAt.toDate().toDateString()}
              <br />
              Ordered At: {order.createdAt.toDate().toLocaleTimeString()}
              <br />
              Order ID: {order.razorpay_order_id}
              <br />
              Payment ID : {order.razorpay_payment_id}
              <br />
              Product ID : {order.productId}
              
            </Typography>
          </Box>
        </Modal>
      </div>
    </div>
  );
}

// Style for the modal
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

export default OrderItemDetails;
