import React, { useState, useEffect } from 'react';
import {
  Typography,
  Grid,
  CircularProgress,
  CssBaseline,
} from '@mui/material';
import { collection, getDocs, getDoc, doc} from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { db } from '../../firebase';
import OrderItem from './orderItem'; 
import cart from '../../assets/images/cart.png';

const OrdersPage = () => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  const { currentUser } = getAuth();

  useEffect(() => {
    const fetchOrders = async () => {
      setLoading(true);
      if (currentUser) {
        try {
          const ordersQuery = collection(db, 'users', currentUser.uid, 'orders');
          const ordersSnapshot = await getDocs(ordersQuery);

          const ordersData = await Promise.all(ordersSnapshot.docs.map(async (order) => {
            const orderItem = order.data();
            const productData = await getDoc(doc(db, 'Products', orderItem.productId));
            if (!productData.exists()) {
              return {
                id: order.id,
                ...orderItem,
                product: null
              }
            }
            return {
              id: order.id,
              ...orderItem,
              product: productData
            };
          }));
          
          setOrders(ordersData);
        } catch (error) {
          console.error('Error fetching orders:', error.message);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchOrders();
  }, [currentUser]);

  return (
    <Grid container component="main" sx={{ marginTop: '5vh', maxWidth: '1000px', marginX: 'auto' }}>
      <CssBaseline />
      <Typography variant="h5" component="div" mb={2} >
        Your Orders
      </Typography>
      <Grid sx={{ margin: "auto" }}>
        {loading && <CircularProgress />}
        {!loading && orders.length === 0 && 
        <>
          <Typography>You have no orders till now !</Typography>
          <div style={{margin: "auto"}} data-aos="fade-left">
              <img src={cart} alt="" width="300px" />
          </div>
        </>}
      </Grid>
      <Grid container spacing={2}>
        {orders.map((order) => (
          <Grid item key={order.id} xs={12} sm={6} md={4}>
            <OrderItem order={order} />
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

export default OrdersPage;
