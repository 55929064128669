import React, { useState, useEffect } from 'react';
import { Box, Typography, TextField, Button, TextareaAutosize, Modal, Paper, MenuItem, Select, FormControl, InputLabel } from '@mui/material';
import categories from '../../../assets/Categories/categories';
import { updateDoc, doc } from 'firebase/firestore';
import { storage, db } from '../../../firebase';
import { ref, uploadBytesResumable, getDownloadURL  } from 'firebase/storage';
import { toast } from 'react-toastify';



function EditProduct({ selectedProduct, handleCloseModal, setProducts, products }) {
  const [productName, setProductName] = useState('');
  const [price, setPrice] = useState('');
  const [maxPrice, setMaxPrice] = useState('');
  const [weight, setWeight] = useState('');
  const [category, setCategory] = useState('');
  const [features, setFeatures] = useState([]);
  const [productDescription, setProductDescription] = useState('');
  const [imageURLs, setImageURLs] = useState([]);
  const [imageFiles, setImageFiles] = useState([]);
  const [isManageImagesModalOpen, setIsManageImagesModalOpen] = useState(false);

  useEffect(() => {
    // Update state with the selected product's values when it changes
    if (selectedProduct) {
      setProductName(selectedProduct.productName || '');
      setPrice(selectedProduct.price || '');
      setMaxPrice(selectedProduct.maxPrice || '');
      setWeight(selectedProduct.weight || '');
      setCategory(selectedProduct.category || '');
      setFeatures(selectedProduct.features || []);
      setProductDescription(selectedProduct.description || '');
      setImageURLs(selectedProduct.imageURLs || []);
    }
  }, [selectedProduct]);

 
  const [newImageFiles, setNewImageFiles] = useState([]);

  const handleAddNewImages = (e) => {
    const files = e.target.files;
    setNewImageFiles([...newImageFiles, ...files]);
  };

  const handleDeleteImage = (index, isExistingImage = true) => {
    if (isExistingImage) {
      // Remove existing image
      const updatedImageURLs = [...imageURLs];
      updatedImageURLs.splice(index, 1);
      setImageURLs(updatedImageURLs);
    } else {
      // Remove newly added image
      const updatedNewImageFiles = [...newImageFiles];
      updatedNewImageFiles.splice(index, 1);
      setNewImageFiles(updatedNewImageFiles);
    }
  };

  const handleManageImages = () => {
    setIsManageImagesModalOpen(true);
  };

  const handleCloseManageImagesModal = () => {
    setIsManageImagesModalOpen(false);
  };

  const handleUpdateProduct = async () => {
    try {
      // Upload new images to Firebase Storage
      const newImageURLs = await uploadImages(newImageFiles);

      // Combine existing and new image URLs
      const words = productName.split(' ');
      const lowerCaseWords = words.map((word) => word.toLowerCase());
      const prefixes = lowerCaseWords.flatMap((word) => {
        return Array.from({ length: word.length }, (_, i) => word.slice(0, i + 1));
      });
      const updatedImageURLs = [...imageURLs, ...newImageURLs];
      const updatedProduct = {
        productName: productName,
        price: price,
        maxPrice: maxPrice,
        weight: weight,
        category: category,
        features: features,
        description: productDescription,
        imageURLs: updatedImageURLs,
        nameContains: prefixes,
      };

      await updateDoc(doc(db, 'Products', selectedProduct.productId), updatedProduct);
      toast.success('Product updated successfully');

      // Refresh the product list after update
      const updatedProducts = products.map((product) =>
        product.productId === selectedProduct.productId ? { ...product, ...updatedProduct } : product
      );
      setProducts(updatedProducts);

      // Clear the form fields after updating a product
      setProductName('');
      setPrice('');
      setMaxPrice('');
      setWeight('');
      setCategory('');
      setFeatures([]);
      setProductDescription('');
      setImageURLs([]);
      setImageFiles([]);
      setNewImageFiles([]);
      handleCloseModal();
    } catch (error) {
      console.error('Error updating product:', error.message);
      toast.error('Error updating product');
    }
  };

  const uploadImages = async (files) => {
    const uploadTasks = files.map((file) => {
      return new Promise((resolve, reject) => {
        const imageRef = ref(storage, `product_images/${file.name}`);
        const uploadTask = uploadBytesResumable(imageRef, file);
  
        uploadTask.on(
          "state_changed",
          (snapshot) => {
            const percent = Math.round(
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            );
            // You can update UI with the upload progress if needed
          },
          (err) => {
            console.error(err);
            reject(err);
          },
          async () => {
            try {
              const url = await getDownloadURL(uploadTask.snapshot.ref);
              resolve(url);
            } catch (error) {
              console.error(error);
              reject(error);
            }
          }
        );
      });
    });
  
    try {
      const uploadedImageURLs = await Promise.all(uploadTasks);
      return uploadedImageURLs;
    } catch (error) {
      console.error("Error uploading images:", error);
      return [];
    }
  };
  


  return (
    <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', bgcolor: 'background.paper', boxShadow: 24, p: 4, width: 400, borderRadius: "2%"  }} >
      <Typography variant="h5">Product Details</Typography>
      <TextField
        label="Product Name"
        variant="outlined"
        value={productName}
        onChange={(e) => setProductName(e.target.value)}
        style={{ marginTop: '10px', marginBottom: '10px' }}
      />
      <TextField
        label="Price"
        variant="outlined"
        value={price}
        onChange={(e) => setPrice(e.target.value)}
        style={{ marginTop: '10px', marginBottom: '10px' }}
      />
      <TextField
        label="Max Price"
        variant="outlined"
        value={maxPrice}
        onChange={(e) => setMaxPrice(e.target.value)}
        style={{ marginTop: '10px', marginBottom: '10px' }}
      />
      <TextField
        label="Weight (g)"
        variant="outlined"
        value={weight}
        onChange={(e) => setWeight(e.target.value)}
        style={{ marginTop: '10px', marginBottom: '10px' }}
      />
      <FormControl style={{ marginTop: '10px', marginBottom: '10px', width: '100%' }}>
        <InputLabel id="category-label">Category</InputLabel>
        <Select
          labelId="category-label"
          label="Category"
          value={category}
          onChange={(e) => setCategory(e.target.value)}
        >
          {categories.map((category) => (
            <MenuItem key={category.value} value={category.value}>
              {category.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <TextareaAutosize
        label="Features (comma-separated)"
        maxRows={10}
        rows={3}
        placeholder="Features (comma-separated)"
        value={features.join('*')}
        onChange={(e) => setFeatures(e.target.value.split('*').map(feature => feature))}
        style={{ marginTop: '10px', marginBottom: '10px', width: '100%' }}
      />
      <TextField
        label="Description"
        rows={5}
        placeholder="Description"
        value={productDescription}
        onChange={(e) => setProductDescription(e.target.value)}
        style={{ marginTop: '10px', marginBottom: '10px', width: '100%' }}
      />
      <Button variant="outlined" onClick={handleManageImages}>
        Manage Images
      </Button>
      <Button variant="outlined" color="primary" onClick={handleUpdateProduct}>
        Update Product
      </Button>
      <Button onClick={handleCloseModal}>Close</Button>

      {/* Manage Images Modal */}
      <Modal open={isManageImagesModalOpen} onClose={handleCloseManageImagesModal}>
        <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', bgcolor: 'background.paper', boxShadow: 24, p: 4, width: 400 }}>
          <Typography variant="h5">Manage Images</Typography>
          <div>
            {imageURLs.map((imageURL, index) => (
              <Paper key={index} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '8px', marginBottom: '8px' }}>
                <img src={imageURL} alt={`Product Image ${index}`} style={{ width: '50px', height: '50px', marginRight: '8px' }} />
                <Button variant="outlined" onClick={() => handleDeleteImage(index)}>
                  Delete
                </Button>
              </Paper>
            ))}
            {newImageFiles.map((file, index) => (
              <Paper key={index} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '8px', marginBottom: '8px' }}>
                <img src={URL.createObjectURL(file)} alt={`New Product Image ${index}`} style={{ width: '50px', height: '50px', marginRight: '8px' }} />
                <Button variant="outlined" onClick={() => handleDeleteImage(index, false)}>
                  Delete
                </Button>
              </Paper>
            ))}
            <input type="file" multiple onChange={handleAddNewImages} />
          </div>
          <Button onClick={handleCloseManageImagesModal}>Save</Button>
        </Box>
      </Modal>
    </Box>
  );
}

export default EditProduct;
