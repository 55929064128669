import React, { useState, useEffect } from 'react';
import { getAuth } from 'firebase/auth';
import { getFirestore, updateDoc, doc, getDoc } from 'firebase/firestore';
import { toast } from 'react-toastify';
import CheckOutButton from './checkOutButton';
import CashOnDelivery from './cashOnDelivery';
import {
  Modal,
  Box,
  Typography,
  Tooltip,
  TextField,
  Grid,
  Paper,
  Button,
  IconButton,
  CircularProgress
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';

const Checkout = ({ cartItems, clearCart }) => {
  const auth = getAuth();
  const [address, setAddress] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [loading, setLoading] = useState(false);
  const [deliveryChargesModalOpen, setDeliveryChargesModalOpen] = useState(false);
  const db = getFirestore();
  useEffect(() => {
    const fetchUserData = async () => {
      if (auth.currentUser) {
        try {
          const userDocRef = doc(db, 'users', auth.currentUser.uid);
          const userDoc = await getDoc(userDocRef);

          if (userDoc.exists()) {
            const userData = userDoc.data();
            setAddress(userData.address || '');
            setPhoneNumber(userData.phoneNumber || '');
            setFirstName(userData.firstName || '');
            setLastName(userData.lastName || '');
          }
        } catch (error) {
          toast.error('Error fetching user data:', error.message);
        }
      }
    };

    fetchUserData();
  }, [auth.currentUser, db]);

  const handleFirstNameChange = (event) => { 
    setFirstName(event.target.value);
  };
  const handleLastNameChange = (event) => { 
    setLastName(event.target.value);
  };
  const handlePhoneNumberChange = (event) => { 
    setPhoneNumber(event.target.value);
  };
  const handleAddressChange = (event) => { 
    setAddress(event.target.value);
  };

  const openDeliveryChargesModal = () => {
    setDeliveryChargesModalOpen(true);
  };

  const closeDeliveryChargesModal = () => {
    setDeliveryChargesModalOpen(false);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      setLoading(true);
      const userDocRef = doc(db, 'users', auth.currentUser.uid);
      await updateDoc(userDocRef, { address: address, phoneNumber: phoneNumber, firstName: firstName, lastName: lastName });
      toast.success('Shipping details updated successfully!');
      setLoading(false);
    } catch (error) {
      toast.error('Error updating Details:', error.message);
    }
  }

  const getTotalPrice = () => {
    return cartItems.reduce((total, item) => {
      if(!item.product){
        return 0; 
      }
      return total + item.quantity * item.product.data().price;
    }, 0) + getDeliveryCharges();
  };
  const getTotalWeight = () => {
    return cartItems.reduce((total, item) => {
      if(!item.product){
        return 0; 
      } 
      return total + item.quantity * item.product.data().weight;
    }, 0);
  }
  const getDeliveryCharges = () => {
    const totalWeight = getTotalWeight();
    if(totalWeight === 0){
      return 0;
    } else if(totalWeight <= 500){
      return 40;
    } else if(totalWeight <= 1000){
      return 100;
    } else if(totalWeight <= 2000){
      return 150;
    } else if(totalWeight <= 5000){
      return 200;
    } else if(totalWeight <= 10000){
      return 300;
    } else if(totalWeight <= 20000){
      return 750;
    } else {
      return 1000;
    }
  }
  return (
    <Grid item xs={12} sx={{ marginTop: 2 }}>
      <Paper elevation={3} sx={{ padding: 2 }}>
        <div data-aos="zoom-in">
          <Typography variant="h5" sx={{fontWeight: "bold"}} gutterBottom color="primary">
            Order Summary
          </Typography>
          <hr />
          {cartItems.map((item) => (
            item.product &&
            <Typography key={item.id} variant="body1" sx={{fontWeight: "bold"}}>
              {item.product.data().productName} (x {item.quantity}) -  &#x20B9; {item.quantity * item.product.data().price}
            </Typography>
          ))}
          <Typography variant="body2" sx={{fontWeight: "bold"}}>
            Delivery Charges: 
            <b> &#x20B9;{getDeliveryCharges()}</b> 
            <Tooltip title="Click for more details" placement="right">
              <IconButton onClick={openDeliveryChargesModal}>
                <InfoIcon />
              </IconButton>
            </Tooltip>
          </Typography>
          <Typography variant="body2" sx={{ marginTop: 1,fontWeight: "bold"}}>
            GST (18%):  <b>&#x20B9;{(getTotalPrice() * 0.18).toFixed(2)}</b> 
          </Typography>
          <Typography variant="body2" sx={{fontWeight: "bold"}}>
            Total Weight:  <b>{getTotalWeight()} grams</b> 
          </Typography>
          <hr />
          <Typography variant="h6" sx={{ marginTop: 2, fontWeight: "bold" }}>
            Total Price:  &#x20B9; {(getTotalPrice() + (getTotalPrice() * 0.18)).toFixed(2)}
          </Typography>
          <TextField
            label="First Name"
            variant="outlined"
            fullWidth
            margin="normal"
            value={firstName}
            onChange={handleFirstNameChange}
          />
          <TextField
            label="Last Name"
            variant="outlined"
            fullWidth
            margin="normal"
            value={lastName}
            onChange={handleLastNameChange}
          />
          <TextField
            label="Phone Number"
            variant="outlined"
            fullWidth
            margin="normal"
            value={phoneNumber}
            onChange={handlePhoneNumberChange}
          />
          <TextField
            multiline
            rows={2}
            label="Address"
            variant="outlined"
            fullWidth
            margin="normal"
            value={address}
            onChange={handleAddressChange}
          />
          <Button
            type="submit"
            variant="contained"
            fullWidth
            color="primary"
            disabled={loading}
            style={{ marginTop: '16px' }}
            onClick={handleSubmit}
          >
            {loading ? <CircularProgress size={24} /> : 'Update shipping details'}
          </Button>
          <CheckOutButton
            deliveryCharge={getDeliveryCharges()}
            totalPrice={getTotalPrice()}
            clearCart={clearCart}
            shippingAddress={address}
            shippingName={firstName + " " + lastName}
            shippingPhone={phoneNumber}
          />
          <CashOnDelivery
            deliveryCharge={getDeliveryCharges()}
            totalPrice={getTotalPrice()}
            clearCart={clearCart}
            shippingAddress={address}
            shippingName={firstName + " " + lastName}
            shippingPhone={phoneNumber}
          />
        </div>
        <Modal
          open={deliveryChargesModalOpen}
          onClose={closeDeliveryChargesModal}
          aria-labelledby="delivery-charges-modal"
          aria-describedby="delivery-charges-description"
        >
          <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', bgcolor: 'background.paper', boxShadow: 24, p: 4, width: 400, borderRadius: "2%" }}>
            <Typography variant="h6" id="modal-modal-title" sx={{ marginBottom: 2 }}>
              Delivery Charges Information
              <hr />
            </Typography>
            <Typography variant="body1" id="modal-modal-description">
              Delivery charges are calculated based on the total weight of your order as follows:
              <ul>
                <li>Up to 500 grams: ₹40</li>
                <li>501 to 1000 grams: ₹100</li>
                <li>1001 to 2000 grams: ₹150</li>
                <li>2001 to 5000 grams: ₹200</li>
                <li>5001 to 10000 grams: ₹300</li>
                <li>10001 to 20000 grams: ₹750</li>
                <li>Above 20000 grams: ₹1000</li>
              </ul>
            </Typography>
          </Box>
        </Modal>
      </Paper>
    </Grid>
  );
  
};

export default Checkout;
