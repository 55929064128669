// ProductFeatures.js
import React from "react";
import { Typography, Card, CardContent, Grid } from "@mui/material";

const ProductFeatures = ({ product }) => (
  <>
      <Typography
        variant="h5"
        component="div"
        mt={2}
        mb={4}
        color="#7A1B1C"
        style={{ fontWeight: "bolder" }}
      >
        Features
      </Typography>
      {product.features ? (
        <Typography variant="body2" component="div">
          {product.features.map((feature, index) => (
            <li key={index}>{feature}</li>
          ))}
        </Typography>
      ) : (
        <Typography variant="body1" component="div">
          No features available.
        </Typography>
      )}
  </>
);

export default ProductFeatures;
