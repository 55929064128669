import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import {Link} from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import { Link as MuiLink} from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Infinity from "../../assets/images/infinityLogo.png";
import {
  getAuth,
  createUserWithEmailAndPassword,
  updateProfile,
} from "firebase/auth";
import { app, db} from "../../firebase";
import { doc, serverTimestamp, setDoc } from "firebase/firestore";
import { toast } from "react-toastify";

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.primary" align="center" {...props}>
      {'Copyright © Infinity Adda '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const auth = getAuth(app);

export default function SignUpSide() {
  const navigate = useNavigate();
  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const { name, email, password, confirmPassword } = Object.fromEntries(formData);
  
    try {
      if(password !== confirmPassword) {
        toast.error("Passwords do not match. Please try again.")
        return;
      }
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      await updateProfile(auth.currentUser, { displayName: name });
      const user = userCredential.user;
      const formDataCopy = { ...formData };
      delete formDataCopy.password;
      formDataCopy.timestamp = serverTimestamp();
      console.log("being done");
      await setDoc(doc(db, "users", user.uid), formDataCopy);
      console.log("being done2");
      toast.success("Signup successful !")
      navigate("/home");
  
    } catch (error) {
      console.error("Error creating user:", error.message);
      toast.error("Signup failed. Please try again.")
    }
  };

  return (
    <Grid container component="main" sx={{ height: "100vh", my:"auto", marginTop: "60px"}}>
        <CssBaseline />

        <Grid container
            sx={{
              maxWidth: 450,
              margin:"auto",
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',

            }}
        >
          <Box
            sx={{
              // border: '1px solid grey',
              borderRadius: '10px',
              boxShadow: 24,
              padding: 4,
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: 'text.primary' , margin: "auto"}}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5" align='center'>
              Sign Up
            </Typography>
            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="name"
                label="Name"
                name="name"
                autoComplete="name"
                autoFocus
              />
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="confirmPassword"
                label="Confirm Password"
                type="password"
                id="confirmPassword"
              />
            <Typography variant="body2" mt={2}>
              By signing up, you agree to our{' '}
              <Link to="/policies" variant="body2">
                <MuiLink>
                  Terms and Conditions
                </MuiLink>
              </Link>
            </Typography>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Sign Up
              </Button>
              <Grid container>
                <Grid item>
                  <Link to="/signin" variant="body2" style={{fontSize: "0.8em"}}>
                    <MuiLink>
                      {"Already have an account? Sign In"}  
                    </MuiLink>
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Grid>
      </Grid>
    // </ThemeProvider>
  );
}