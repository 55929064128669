import React, { useState } from 'react';
import { Typography, Card, CardContent, Box, Rating, IconButton, Dialog, DialogActions, DialogContent, Button } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { toast } from 'react-toastify';
import { db } from '../../firebase';
import {collection, doc, deleteDoc} from 'firebase/firestore';
const ProductReviews = ({ reviews, user, productId }) => {
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteReviewId, setDeleteReviewId] = useState(null);

  const handleEdit = (reviewId) => {
    alert(`Edit review with ID: ${reviewId}`);
  };

  const handleDelete = (reviewId) => {
    setDeleteReviewId(reviewId);
    setDeleteModalOpen(true);
  };

  const handleDeleteConfirm = async () => {
    try {
      console.log(deleteReviewId);
      await db.collection('Products').doc(productId).collection('Reviews').doc(deleteReviewId).delete();
      toast.success('Review deleted successfully');
    } catch (error) {
      console.error('Error deleting review:', error.message);
      alert('An error occurred while deleting the review');
    } finally {
      setDeleteReviewId(null);
      setDeleteModalOpen(false);
    }
  };

  const handleDeleteCancel = () => {
    setDeleteReviewId(null);
    setDeleteModalOpen(false);
  };

  return (
    <>
        <Typography variant="h5" component="div" mt={2} mb={4} color="#7A1B1C" style={{fontWeight: "bolder"}}>
          Reviews and Ratings
        </Typography>
        <hr />
        {reviews.map(review => (
          <div data-aos="zoom-in">
            <Box key={review.id} sx={{ borderBottom: '1px solid #ccc', marginBottom: 3, paddingBottom: 2 }}>
              <Typography variant="subtitle1" component="div" gutterBottom>
                <Rating value={review.rating} precision={0.5} readOnly />
              </Typography>
              <Typography variant="body1" component="div" mb={1}>
                {review.review}
              </Typography>
              <Typography variant="subtitle2" component="div" color="textSecondary">
                <strong >{review.userName}</strong> - {new Date(review.timestamp).toLocaleString()}
              </Typography>
              {user && user.uid === review.userId && (
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 1 }}>
                  <IconButton onClick={() => handleEdit(review.id)}>
                    <EditIcon />
                  </IconButton>
                  <IconButton onClick={() => handleDelete(review.id)}>
                    <DeleteIcon />
                  </IconButton>
                </Box>
              )}
            </Box>

          </div>
        ))}
        {reviews.length === 0 && (
          <Typography variant="body2" color="textSecondary">
            No reviews yet.
          </Typography>
        )}

      {/* Delete Confirmation Modal */}
      <Dialog open={isDeleteModalOpen} onClose={handleDeleteCancel}>
        <DialogContent>
          Are you sure you want to delete this review?
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteCancel} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteConfirm} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>

  );
};

export default ProductReviews;
