import React from "react";
import { Button, Paper, Typography, Grid } from "@mui/material";
import { Link } from "react-router-dom";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";

function ManageProduct({ product, handleEditProduct, handleOpenDeleteModal }) {
  return (
    <Paper
    key={product.id}
    elevation={3}
    style={{ padding: "20px", marginBottom: "20px", textAlign: "center", maxWidth: "500px" }}
    >
          <Typography variant="h6" gutterBottom>
            <b>{product.productName}</b> 
          </Typography>
          <hr style={{ border: "0.5px solid #ccc", marginBottom: "15px" }} />
        <Button onClick={() => handleEditProduct(product)} variant="outlined" sx={{margin: "5px"}}>
          <b>
            <EditOutlinedIcon />
          </b>
        </Button>
        <Button onClick={() => handleOpenDeleteModal(product)} variant="outlined" sx={{margin: "5px"}}>
          <b>
            <DeleteOutlineOutlinedIcon />{" "}
          </b>
        </Button>

        <Link
          to={`/products/${product.productId}`}
          style={{ textDecoration: "none" }}
        >
          <Button variant="contained" color="primary" sx={{margin: "5px"}}>
            View Product
          </Button>
        </Link>
    </Paper>
  );
}

export default ManageProduct;
