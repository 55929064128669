import React, { useState, useEffect, useRef } from 'react';
import { InputBase, IconButton, Paper, List, ListItem, ListItemText } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useNavigate } from 'react-router-dom';
import Filter from './filter';

function SearchBar() {
  const [searchQuery, setSearchQuery] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const navigate = useNavigate();
  const suggestionRef = useRef(null);

  const predefinedSuggestions = [
      'battery',
      'battery cell',
      'battery management system',
      'capacitor',
      'charger',
      'generator',
      'heatsink',
      'humidifiers',
      'inverter',
      'jumper cables',
      'junction box',
      'kilowatt',
      'LED',
      'microcontroller',
      'multimeter',
      'network adapter',
      'ohm',
      'oscilloscope',
      'power banks',
      'power supply',
      'relay',
      'resistor',
      'solar panel',
      'switch',
      'terminal',
      'transformer',
      'transistor',
      'USB cable',
      'voltmeter',
      'voltage regulator',
      'xenon lamp',
      'zero ohm resistor',
      'cell spacers',
      'cell',

    ];
    

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchQuery(value);
    navigate(`/search/query=${encodeURIComponent(value.toLowerCase())}`);

    const filteredSuggestions = predefinedSuggestions.filter((suggestion) =>
      suggestion.toLowerCase().includes(value.toLowerCase())
    );

    setSuggestions(filteredSuggestions);
  };

  const handleSuggestionClick = (suggestion) => {
    handleSearchChange({ target: { value: suggestion } });
    setSearchQuery(suggestion);
    setSuggestions([]);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (suggestionRef.current && !suggestionRef.current.contains(event.target)) {
        setSuggestions([]);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <Paper elevation={0} style={{ backgroundColor: "rgba(35, 43, 54, 0.6)", borderRadius: "16px", display: 'flex', alignItems: 'center', margin: "10px", position: 'relative' }}>
      <IconButton style={{ color: "white", marginLeft: "10px" }}>
        <SearchIcon />
      </IconButton>
      <InputBase
        placeholder="Search"
        inputProps={{ 'aria-label': 'search' }}
        sx={{ color: "white", marginLeft: "10px", maxWidth: "280px" }}
        value={searchQuery}
        onChange={handleSearchChange}
      />
      <Filter />
      {suggestions.length > 0 && (
        <List ref={suggestionRef} sx={{ position: 'absolute', zIndex: 1, width: "100%", backgroundColor: 'rgba(10, 10, 10, 0.9)', borderRadius: '4px', top: '100%', left: 0, maxHeight: '200px', color: "white" }}>
          {suggestions.map((suggestion, index) => (
            <ListItem button key={index} onClick={() => handleSuggestionClick(suggestion)}>
              <ListItemText primary={suggestion} />
            </ListItem>
          ))}
        </List>
      )}
    </Paper>
  );
}

export default SearchBar;