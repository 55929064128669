import React from 'react';
import { Grid, Accordion, AccordionSummary, AccordionDetails, Typography, List } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ContactUs from './components/contactUs';
import PricingPolicy from './components/pricingPolicy';
import PrivacyPolicy from './components/privacyPolicy';
import RefundsAndCancellationPolicy from './components/refundsAndCancellation';
import TermsAndConditions from './components/termsAndConditions';
import ShippingPolicy from './components/shippingPolicy';
import About from '../About';
function Policies() {
  const policyComponents = [
    { label : 'About', component : <About />},
    { label: 'Terms and Conditions', component: <TermsAndConditions /> },
    { label: 'Privacy Policy', component: <PrivacyPolicy /> },
    { label: 'Pricing Policy', component: <PricingPolicy /> },
    { label: 'Shipping Policy', component: <ShippingPolicy /> },
    { label: 'Refunds and Cancellation Policy', component: <RefundsAndCancellationPolicy /> },
    { label: 'Contact Us', component: <ContactUs /> },
  ];

  return (
    <Grid container component="main" sx={{ marginTop: '5vh', textAlign: "justify" }}>
      <CssBaseline />
      <List sx={{ width: '80%', margin: 'auto', padding: "10px", marginTop: "2em"}} data-aos="fade-left">
        {policyComponents.map((policy, index) => (
          <Accordion key={index} sx={{ width: '100%' }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography sx={{fontWeight: "bold", fontSize: "1.2em", color: "#7A1B1C", textAlign:"left"}}>{policy.label}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {policy.component}
            </AccordionDetails>
          </Accordion>
        ))}
      </List>
    </Grid>
  );
}

export default Policies;
