import React, { useEffect, useState } from "react";
import { Link }  from "react-router-dom"; 
import { getAuth } from "firebase/auth";
import { app } from "../../firebase";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import {
  AppBar,
  Box,
  Container,
  Badge,
  Drawer,
  List, 
  ListItem,
  useMediaQuery,
  Link as MuiLink,
} from "@mui/material";
import {
  NavToolbar,
  pages,
  MenuButton,
  colors,
} from "./tools";
import Infinity from "../../assets/images/infinityLogoNAV.png";
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCartOutlined';
import ExitToAppOutlinedIcon from '@mui/icons-material/ExitToAppOutlined';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import SearchBar from "./components/searchBar";
import { collection, getDocs, query } from 'firebase/firestore';
import { db } from '../../firebase';

const auth = getAuth(app);

const Navbar = () => {
  const [openMenu, setOpenMenu] = useState(false);
  const [cartItems, setCartItems] = useState([]);
  const navigate = useNavigate();
  const [user] = useAuthState(auth);
  useEffect(() => {
    const fetchCartItems = async () => {
      if (user) {
        const cartQuery = query(
          collection(db, `users/${user.uid}/cart`)
        );

        const snapshot = await getDocs(cartQuery);
        const items = snapshot.docs.map((doc) => doc.data());
        setCartItems(items);
      }
    };

    fetchCartItems();
  }, [user]);

  const handleLogOut = async () => {
    try {
      await auth.signOut();
      toast.success("Good Bye 🚀");
      navigate("/signin");
    } catch(e) {
      console.error(e.message)
      toast.error("Error occurred");
    }
  };
  const adminEmail = process.env.REACT_APP_ADMIN_EMAIL;
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  return (
    <AppBar         
      sx={{
        background: "rgb(255,255,255)",
        background: "linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(10,10,10,0.8128501400560224) 0%, rgba(0,0,0,1) 75%, rgba(0,0,0,1) 100%)",
      border: "none",
      boxShadow: "none",
      height: "80px",
      paddingX: isMobile ? "2%" : "13%",
      // position: "absolute",
      }}>
        <NavToolbar disableGutters>
          <Box>
            <MuiLink href="/" color={colors.textColor}>
              <img src={Infinity} alt="Infinity_Adda" style={{height: isMobile? "70px": "80px"}}/>
            </MuiLink>
          </Box>
          <Box>
            <SearchBar></SearchBar> 
          </Box>
          <Box sx={{ display: { xs: "none", md: "flex" } }}>
            {pages.map((page) => (
              <Link to={page.link}>
                <MenuButton component="a" href={page.link} key={page.name}> 
                  {page.name}
                </MenuButton> 
              </Link>
            ))}
            {!user ? (
              <Link to="/signin">
                <MenuButton component="a" key="signin"> 
                  SIGN IN
                </MenuButton> 
              </Link>
            ) : (
              <>
                <Link to="/orders">
                  <MenuButton component="a" key="cart"> 
                    ORDERS
                  </MenuButton> 
                </Link>
                {user.email === adminEmail && (
                  <Link to="/admin">
                    <MenuButton component="a" key="admin"> 
                      ADMIN
                    </MenuButton> 
                  </Link>
                )}
                <Link to="/cart">
                  <MenuButton component="a" key="cart"> 
                  <Badge badgeContent={cartItems.length} color="primary">
                    <ShoppingCartIcon />
                  </Badge>
                  </MenuButton> 
                </Link>
                <Link to="/profile">
                  <MenuButton component="a" key="profile"> 
                    <AccountCircleOutlinedIcon />
                  </MenuButton> 
                </Link>
                <Link >
                  <MenuButton component="a" onClick={handleLogOut} key="logout"> 
                    <ExitToAppOutlinedIcon />
                  </MenuButton> 
                </Link>
              </>
            )}
          </Box>
          <Box
            sx={{
              display: { xs: "flex", md: "none" },
              alignItems: "center",
              textAlign: "center",
              backgroundColor: "black"
            }}
          >
            <button onClick={() => setOpenMenu(!openMenu)} style={{background: "black", border: "none", boxShadow: "none"}}>
             <MenuOutlinedIcon sx={{fontSize: "30px", color: "white"}} />
            </button>
            <Drawer
              PaperProps={{
                sx: {
                  backgroundColor: "black",
                  // background: "none",
                  width: "200px",
                  alignItems:"center",
                },
              }}
              
              anchor="left"
              open={openMenu}
              onClose={() => setOpenMenu(!openMenu)}
            >
              <MuiLink
                href="/"

                sx={{
                  textAlign: "center",
                  color: "#FFFFFF",
                  padding: "10px",
                  display: "block", // Add this line to make the link centered
                }}
              >
                <img src={Infinity} alt="Infinity_Adda" height="100" />
              </MuiLink>
              <List
                sx={{
                  color: "#FFFFFF",
                  textAlign: "center", 
                  alignItems: "center",
                }}
              >
                {pages.map((page, index) => (
                  <ListItem disablePadding key={index}>
                    <MuiLink>
                      <Link onClick={() => setOpenMenu(!openMenu)} to={page.link} >
                        <MenuButton key={page.name}>{page.name}</MenuButton>
                      </Link>
                    </MuiLink>

                  </ListItem>
                ))}
                { !user ? (
                  <ListItem disablePadding >   
                    <MuiLink>
                      <Link to="/signin">
                        <MenuButton key="signin">SIGN IN</MenuButton>
                      </Link>
                    </MuiLink>
                  </ListItem>
                ) : (
                  <>
                    {user.email === adminEmail && (
                      <ListItem disablePadding >
                        <MuiLink>
                          <Link to="/admin"  onClick={() => setOpenMenu(!openMenu)}>
                            <MenuButton key="admin">ADMIN</MenuButton>
                          </Link>
                        </MuiLink>
                      </ListItem> 
                    )}

                    <ListItem disablePadding >   
                      <MuiLink>
                        <Link to="/cart"  onClick={() => setOpenMenu(!openMenu)}>
                          <MenuButton key="cart">CART</MenuButton>
                        </Link>
                      </MuiLink>
                    </ListItem>
                    <ListItem disablePadding >   
                      <MuiLink>
                        <Link to="/orders"  onClick={() => setOpenMenu(!openMenu)}>
                          <MenuButton key="orders">ORDERS</MenuButton>
                        </Link>
                      </MuiLink>
                    </ListItem>
                    <ListItem disablePadding >   
                      <MuiLink>
                        <Link to="/profile"  onClick={() => setOpenMenu(!openMenu)}>
                          <MenuButton key="profile">PROFILE</MenuButton>
                        </Link>
                      </MuiLink>
                    </ListItem>
                    <ListItem disablePadding  onClick={() => setOpenMenu(!openMenu)}>   
                      <MuiLink>
                        <MenuButton onClick={handleLogOut} key="logout">LOG OUT</MenuButton>
                      </MuiLink>
                    </ListItem>
                  </>
                )}
              </List>
            </Drawer>
          </Box>
        </NavToolbar>
    </AppBar>
  );
};

export default Navbar;

