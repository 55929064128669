import React from 'react';
import { Typography } from '@mui/material';

function RefundsAndCancellationPolicy() {
  return (
    <div>
      <hr />
      <Typography>
        Welcome to InfinityAdda. This Refund and Cancellation Policy outlines the terms and conditions related to the refund and cancellation of orders placed through our website.
      </Typography>
      <br />

      <Typography fontWeight="bold">
        1. Refund Policy
      </Typography>
      <Typography>
        We do not generally accept returns or offer refunds for products purchased on InfinityAdda. However, we understand that there may be exceptional cases where a refund is necessary.
      </Typography>
      <br />

      <Typography fontWeight="bold">
        2. Contact Us for Refunds
      </Typography>
      <Typography>
        If you believe your situation qualifies for a refund, please contact our customer support team within 7 days of receiving your order. We will assess the circumstances and process refunds on a case-by-case basis.
      </Typography>
      <br />

      <Typography fontWeight="bold">
        3. Cancellation Policy
      </Typography>
      <Typography>
        Orders can not be canceled after the purchase. Once the order is placed, orders are processed for shipping, and cancellations will not be accepted.
      </Typography>
      <br />

      <Typography fontWeight="bold">
        4. Refund Process
      </Typography>
      <Typography>
        If your refund request is approved, the refund will be processed using the original payment method. Please note that it may take 7 working days to credit the refunded amount to your account.
      </Typography>
      <br />

      <Typography fontWeight="bold">
        5. Changes to Refund and Cancellation Policy
      </Typography>
      <Typography>
        InfinityAdda reserves the right to modify or update this Refund and Cancellation Policy at any time. Changes will be effective immediately upon posting to the website.
      </Typography>
    </div>
  );
}

export default RefundsAndCancellationPolicy;
