import React from 'react';
import { Typography } from '@mui/material';

function PricingPolicy() {
  return (
    <div>
      <hr />
      <Typography>
        Welcome to InfinityAdda. This Pricing Policy outlines the terms and conditions related to the pricing of our services and any associated fees.
      </Typography>
      <br />

      <Typography fontWeight="bold">
        1. Service Charges
      </Typography>
      <Typography>
        InfinityAdda may charge fees for certain services. The details of these charges will be clearly communicated to you before you use the respective services.
      </Typography>
      <br />

      <Typography fontWeight="bold">
        2. Payment Terms
      </Typography>
      <Typography>
        Payment terms, including the accepted payment methods, due dates, and any applicable late fees, will be specified in your account or during the checkout process.
      </Typography>
      <br />

      <Typography fontWeight="bold">
        3. Discounts and Promotions
      </Typography>
      <Typography>
        InfinityAdda may offer discounts or promotions from time to time. The terms and conditions for these offers, including eligibility criteria and expiration dates, will be provided at the time of the offer.
      </Typography>
      <br />

      <Typography fontWeight="bold">
        4. Refunds
      </Typography>
      <Typography>
        Refund policies, if applicable, will be clearly stated. Any conditions or timeframes for requesting refunds will also be outlined.
      </Typography>
      <br />

      <Typography fontWeight="bold">
        5. Changes to Pricing Policy
      </Typography>
      <Typography>
        InfinityAdda reserves the right to modify or update this Pricing Policy at any time. Changes will be effective immediately upon posting to the website.
      </Typography>
    </div>
  );
}

export default PricingPolicy;
