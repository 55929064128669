const categories = [
  {
    value: "Battery Management System",
    label: "Battery Management System",
    logo: "https://firebasestorage.googleapis.com/v0/b/infinity-adda.appspot.com/o/categoryIcons%2Fbattery.png?alt=media&token=e7603deb-0ca6-4cf7-8acd-9e4b61573952",
  },
  {
    value: "Battery packs (E vehicle grade)",
    label: "Battery packs (E vehicle grade)",
    logo: "https://firebasestorage.googleapis.com/v0/b/infinity-adda.appspot.com/o/categoryIcons%2Fcharging-station.png?alt=media&token=f5fe8e55-4485-4f74-aae9-117cc2aca198",
  },
  {
    value: "Battery Packs (Normal grade)",
    label: "Battery Packs (Normal grade)",
    logo: "https://firebasestorage.googleapis.com/v0/b/infinity-adda.appspot.com/o/categoryIcons%2Fbattery%20(1).png?alt=media&token=68011f46-42e1-4a98-b960-6f19edac8682",
  },
  {
    value: "Rechargeable Lithium Ion Cells",
    label: "Rechargeable Lithium Ion Cells",
    logo: "https://firebasestorage.googleapis.com/v0/b/infinity-adda.appspot.com/o/categoryIcons%2Fbattery%20(2).png?alt=media&token=dab80031-8f37-4c96-aed9-5c2f0790a0b8",
  },
  {
    value: "Nickel and Sleeves",
    label: "Nickel and Sleeves",
    logo: "https://firebasestorage.googleapis.com/v0/b/infinity-adda.appspot.com/o/categoryIcons%2Fnickel.png?alt=media&token=3e6ddead-2fcc-41a1-8298-8df349b823c70",
  },
  {
    value: "Power Bank",
    label: "Power Bank",
    logo: "https://firebasestorage.googleapis.com/v0/b/infinity-adda.appspot.com/o/categoryIcons%2Fpowerbank.png?alt=media&token=c4d233cd-927c-440c-9002-f9f5bed94ea6",
  },
  {
    value: "Security Systems",
    label: "Security Systems",
    logo: "https://firebasestorage.googleapis.com/v0/b/infinity-adda.appspot.com/o/categoryIcons%2Fsecurity.png?alt=media&token=71c44eab-332b-4322-94e6-fd1e27c744bb",
  },
  {
    value: "Cell Spacers",
    label: "Cell Spacers",
    logo: "https://firebasestorage.googleapis.com/v0/b/infinity-adda.appspot.com/o/categoryIcons%2Fbattery%20(3).png?alt=media&token=8cec6af2-90a8-4523-8c88-964fc97a46d7",
  },
  {
    value: "LEDs",
    label: "LEDs",
    logo: "https://firebasestorage.googleapis.com/v0/b/infinity-adda.appspot.com/o/categoryIcons%2Frgb.png?alt=media&token=eb6beebb-db4d-46c1-b49b-17c3dc21e106",
  },
  {
    value: "MOSFETs",
    label: "MOSFETs",
    logo: "https://firebasestorage.googleapis.com/v0/b/infinity-adda.appspot.com/o/categoryIcons%2Fmosfet.png?alt=media&token=1d06cf39-75cc-4520-8453-023f4272e3d4",
  },
  {
    value: "Displays",
    label: "Displays",
    logo: "https://firebasestorage.googleapis.com/v0/b/infinity-adda.appspot.com/o/categoryIcons%2Fdisplay.png?alt=media&token=23f1bb9b-97d3-48cd-a2d1-97fb3e16dea5",
  },
  {
    value: "IOT Components",
    label: "IOT Components",
    logo: "https://firebasestorage.googleapis.com/v0/b/infinity-adda.appspot.com/o/categoryIcons%2Fiot.png?alt=media&token=ceee08a5-1675-4062-b35e-95938e1b041f",
  },
  {
    value: "Speakers",
    label: "Speakers",
    logo: "https://firebasestorage.googleapis.com/v0/b/infinity-adda.appspot.com/o/categoryIcons%2Floud-speaker.png?alt=media&token=79faa842-c77c-44dd-8aca-4b6323137360",
  },
  {
    value: "Rechargeable Lithium Phosphate Cells",
    label: "Rechargeable Lithium Phosphate Cells",
    logo: "https://firebasestorage.googleapis.com/v0/b/infinity-adda.appspot.com/o/categoryIcons%2Fbattery%20(2).png?alt=media&token=dab80031-8f37-4c96-aed9-5c2f0790a0b8",
  },
  {
    value: "Lithium Polymer Cells",
    label: "Lithium Polymer Cells",
    logo: "https://firebasestorage.googleapis.com/v0/b/infinity-adda.appspot.com/o/categoryIcons%2Flithium-cell.png?alt=media&token=f28728b9-acc5-4967-8bae-e855e5ce8b45",
  },
  {
    value: "Modules",
    label: "Modules",
    logo: "https://firebasestorage.googleapis.com/v0/b/infinity-adda.appspot.com/o/categoryIcons%2Fsensor.png?alt=media&token=49d4576e-2ce9-40e2-b926-eded32fdcdcc",
  },
  {
    value: "Integrated Circuits ICs",
    label: "Integrated Circuits ICs",
    logo: "https://firebasestorage.googleapis.com/v0/b/infinity-adda.appspot.com/o/categoryIcons%2Fprocessor.png?alt=media&token=abde6f0d-ae06-42f5-b8fd-09b2b3432103",
  },
  {
    value: "Thermal Compound",
    label: "Thermal Compound",
    logo: "https://firebasestorage.googleapis.com/v0/b/infinity-adda.appspot.com/o/categoryIcons%2Fbody.png?alt=media&token=ee034925-3162-43f2-90ed-e1eab4b7ce22",
  },
  {
    value: "Sensors",
    label: "Sensors",
    logo: "https://firebasestorage.googleapis.com/v0/b/infinity-adda.appspot.com/o/categoryIcons%2Fmotion-sensor.png?alt=media&token=2fd245c6-9087-47e0-abfc-e2bf37e6e706",
  },
  {
    value: "Transformers",
    label: "Transformers",
    logo: "https://firebasestorage.googleapis.com/v0/b/infinity-adda.appspot.com/o/categoryIcons%2Fpower-transformer.png?alt=media&token=92e6c805-f2e7-48c4-8100-bf2c79bc4e22",
  },
  {
    value: "Switches",
    label: "Switches",
    logo: "https://firebasestorage.googleapis.com/v0/b/infinity-adda.appspot.com/o/categoryIcons%2Fpower-switch.png?alt=media&token=555bedbb-134a-4006-aca4-0c17d0fd1bd0",
  },
  {
    value: "Amplifiers",
    label: "Amplifiers",
    logo: "https://firebasestorage.googleapis.com/v0/b/infinity-adda.appspot.com/o/categoryIcons%2Famplifier.png?alt=media&token=84345ce8-e0a2-4908-879f-0852da6e5dc1",
  },
  {
    value: "Diodes",
    label: "Diodes",
    logo: "https://firebasestorage.googleapis.com/v0/b/infinity-adda.appspot.com/o/categoryIcons%2Fdiode.png?alt=media&token=444465f3-16ff-4902-9977-e8171eee4d10",
  },
  {
    value: "Motors",
    label: "Motors",
    logo: "https://firebasestorage.googleapis.com/v0/b/infinity-adda.appspot.com/o/categoryIcons%2Felectric-motor.png?alt=media&token=8812365f-97cb-4389-a598-092ae7664458",
  },
  {
    value: "Measuring Instruments (Meters)",
    label: "Measuring Instruments (Meters)",
    logo: "https://firebasestorage.googleapis.com/v0/b/infinity-adda.appspot.com/o/categoryIcons%2Ffast.png?alt=media&token=141ba1f6-3296-4775-9b42-61c1a2d5d11a",
  },
  {
    value: "Others",
    label: "Others",
    logo: "https://firebasestorage.googleapis.com/v0/b/infinity-adda.appspot.com/o/categoryIcons%2Fmore.png?alt=media&token=a4751fae-3ef6-4b63-bf77-5237336afad0",
  },
];

export default categories;
