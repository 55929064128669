// Product.js
import React from "react";
import { Typography, Card, CardContent, Grid, useMediaQuery, } from "@mui/material";
import Slider from "react-slick";

function Product  ({ product, settings }){
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  return (
    <Card sx={{ display: "flex", flexDirection: "column" }}>
      <Typography
          variant="h6"
          gutterBottom
          sx={{
            background:
              "linear-gradient(90deg, rgba(87,33,33,1) 0%, rgba(0,0,0,1) 100%, rgba(0,0,0,1) 100%)",
            padding: "10px",
            borderRadius: "5px",
            color: "white",
            fontWeight: "bold",
            fontSize: "14px",
            marginBottom: "0px",
            marginTop: "10px",
            paddingX:"30px",
            // boxShadow: "rgb(38, 57, 77) 0px 20px 30px -10px",
          }}
        >
          <div data-aos="zoom-in">Infinity Verified</div>
        </Typography>
      <CardContent sx={{ flex: "1 1 auto", overflow: "hidden" }}>
        {product.imageURLs && product.imageURLs.length > 0 && (
          <Slider {...settings} mt={2}>
            {product.imageURLs.map((imageUrl, index) => (
              <img
                key={index}
                src={imageUrl}
                alt={`Product: ${product.productName}`}
                sx={{
                  width: "100%",
                  height: "auto",
                  objectFit: "cover",
                  borderRadius: "8px",
                  marginBottom: "10px",
                }}
              />
            ))}
          </Slider>
        )}
      </CardContent>
    </Card>

  )
};

export default Product;
