import React from 'react';
import { Typography, Link } from '@mui/material';

function ContactUs() {
  return (
    <div>
      <hr />
      <Typography fontWeight="bold">
        Operating Address
      </Typography>
      <Typography>
        Maruti Nagar, Opposite Hanuman Mandir, Satna, Madhya Pradesh, 485001.
      </Typography>
      <br />
      <Typography fontWeight="bold">
        Customer care
      </Typography>
      <Typography>
        For customer care services, you can contact us at:
      </Typography>

      <Typography>
        Email: <Link href="mailto:shivammishracreator2@gmail.com">shivammishracreator2@gmail.com</Link>
      </Typography>

      <Typography>
        Phone: +91 7879070809
      </Typography>
      <br />
      <Typography fontWeight="bold">
        Technical Feedback
      </Typography>
      <Typography>
        For technical enquiries or feedback, please email us at:
      </Typography>

      <Typography>
        Email: <Link href="mailto:shivanshdubey232@gmail.com">shivanshdubey232@gmail.com</Link>
      </Typography>
    </div>
  );
}

export default ContactUs;
