// ProductDetail.js
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Typography,
  CircularProgress,
  LinearProgress,
  Grid,
  CssBaseline,
  Button,
  Card,
  CardContent,
  Tab,
  Tabs,
  Box,
  useMediaQuery,
} from "@mui/material";
import { Link } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { getDoc, doc, getDocs, collection } from "firebase/firestore";
import { db } from "../../firebase";
import Product from "./product";
import ProductDescription from "./productDescription";
import ProductFeatures from "./productFeatures";
import ProductReviews from "./productReviews";
import ProductPostReview from "./productPostReview";
import ProductPrice from "./productPrice";
import AddToCart from "./addToCart";
import { app } from "../../firebase";
import { getAuth } from "firebase/auth";
import { useAuthState } from "react-firebase-hooks/auth";

const auth = getAuth(app);

function ProductDetail() {
  const { productId } = useParams();
  const [reviews, setReviews] = useState([]);
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(true);
  const [user] = useAuthState(auth);
  const [userName, setUserName] = useState("Guest");
  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const productDoc = await getDoc(doc(db, "Products", productId));
        if (user) {
          const userDoc = await getDoc(doc(db, "users", user.uid));
          if (userDoc.exists()) {
            const currentUserName =
              userDoc.data().firstName + " " + userDoc.data().lastName;
            setUserName(currentUserName);
          }
        }
        if (productDoc.exists()) {
          setProduct({
            id: productDoc.id,
            ...productDoc.data(),
          });

          const reviewsSnapshot = await getDocs(
            collection(productDoc.ref, "Reviews")
          );
          const reviewsData = reviewsSnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setReviews(reviewsData);
        } else {
          console.error("Product not found");
        }

        setLoading(false);
      } catch (error) {
        console.error("Error fetching product:", error.message);
        setLoading(false);
      }
    };

    fetchProduct();
  }, [productId]);

  const updateReviews = async () => {
    const reviewsSnapshot = await getDocs(
      collection(db, "Products", productId, "Reviews")
    );
    const updatedReviews = reviewsSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    setReviews(updatedReviews);
  };

  const renderPostReviewSection = () => {
    if (user && userName !== "Guest") {
      return (
        <>
          <ProductPostReview
            productId={productId}
            updateReviews={updateReviews}
            userName={userName}
          />
        </>
      );
    } else {
      return (
        <>
          <Card sx={{ marginTop: "1em" }}>
            <CardContent>
              <Typography variant="h6">
                Update your Profile to post Reviews !
              </Typography>
              <Button
                component={Link}
                to="/profile"
                variant="contained"
                color="primary"
                sx={{ marginTop: "10px", width: "100%" }}
              >
                Go to Profile
              </Button>
            </CardContent>
          </Card>
        </>
      );
    }
  };
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  return (
    <Grid
      container
      component="main"
      sx={{ maxWidth: "1000px", marginX: "auto" }}
      style={{ backgroundColor: "white", padding: isMobile ? "0px" : "20px" }}
    >
      <CssBaseline />
      {loading ? (
        <div style={{ width: "100%", margin: "auto" }}>
          <LinearProgress />
        </div>
      ) : product ? (
        <>
          <Grid container sx={{}}>
            <Grid
              xs={12}
              sm={10}
              md={6}
              lg={6}
              sx={{ mx: "auto", padding: isMobile ? "0px" : "10px"}}
            >
              <div>
                <Product product={product} settings={settings} />
              </div>
            </Grid>
            <Grid
              xs={12}
              sm={10}
              md={6}
              lg={6}
              sx={{ mx: "auto", textAlign: "justify", padding: isMobile? "0px" : "10px" }}
            >
              <ProductPrice product={product}  isMobile={isMobile}/>
              <AddToCart productId={productId} isMobile={isMobile}/>
              <div>
                {user ? (
                  renderPostReviewSection()
                ) : (
                  <Card sx={{ backgroundColor: "#EEEEEE", marginTop: "1em" }}>
                    <CardContent>
                      <Typography variant="h6">
                        Please Signup to post Reviews !
                      </Typography>
                      <Button
                        component={Link}
                        to="/signup"
                        variant="contained"
                        color="primary"
                        sx={{ marginTop: "10px", width: "100%" }}
                      >
                        Sign Up
                      </Button>
                    </CardContent>
                  </Card>
                )}
              </div>
            </Grid>
          </Grid>
            <Grid item xs={12} sx={{ mx: "auto", padding:  "0px", margin: "0px"}} >
              <Tabs
                value={tabValue}
                onChange={handleTabChange}
                aria-label="product tabs"
                scrollButtons="auto"
                variant="scrollable"
                allowScrollButtonsMobile
              >
                <Tab label="Description" style={{fontWeight: "bold"}}/>
                <Tab label="Features" style={{fontWeight: "bold"}}/>
                <Tab label="Reviews" style={{fontWeight: "bold"}}/>
              </Tabs>
              <TabPanel value={tabValue} index={0}>
                <ProductDescription product={product} isMobile={isMobile} />
              </TabPanel>
              <TabPanel value={tabValue} index={1}>
                <ProductFeatures product={product} isMobile={isMobile} />
              </TabPanel>
              <TabPanel value={tabValue} index={2}>
                <ProductReviews
                  reviews={reviews}
                  user={user}
                  productId={productId}
                  isMobile={isMobile}
                />
              </TabPanel>
            </Grid>

        </>
      ) : (
        <Typography variant="body1" component="div">
          Product not found.
        </Typography>
      )}
    </Grid>
  );
}
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

export default ProductDetail;
