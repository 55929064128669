import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyDDjEoR2hb3WcfTNSKOH_pgDwmJOsSRe5c",
  authDomain: "infinity-adda.firebaseapp.com",
  projectId: "infinity-adda",
  storageBucket: "infinity-adda.appspot.com",
  messagingSenderId: "908571803158",
  appId: "1:908571803158:web:81a283c52bc470b37bff37",
  databaseURL: "https://infinity-adda-default-rtdb.firebaseio.com",
};

export const app = initializeApp(firebaseConfig);
export const db = getFirestore();
export const storage = getStorage(app);