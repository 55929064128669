// ProductDescription.js
import React from "react";
import { Typography, Card, CardContent, Grid } from "@mui/material";

const ProductDescription = ({ product }) => (
  <>
      <Typography
        variant="h5"
        component="div"
        mt={2}
        mb={4}
        color="#7A1B1C"
        style={{ fontWeight: "bolder" }}
      >
        Description
      </Typography>
      <hr />
      {product.description ? (
        <Typography variant="body2" component="div" style={{textAlign: "justify"}}>
          {product.description}
        </Typography>
      ) : (
        <Typography variant="body1" component="div" >
          No description available.
        </Typography>
      )}
  </>
);

export default ProductDescription;
