import React, { useState } from "react";
import Carousel from 'react-bootstrap/Carousel';
import "bootstrap/dist/css/bootstrap.min.css";
import img1 from "../../../assets/images/photo3.jpg";
import img2 from "../../../assets/images/photo2.jpg";
import img3 from "../../../assets/images/photo1.jpg";
import img4 from "../../../assets/images/photo4.jpg";
import { useMediaQuery } from '@mui/material';

function Sliderimg() {
  const [index, setIndex] = useState(0);
  const isPhone = useMediaQuery('(max-width:600px)'); // Detect phone screens

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  return (
    <Carousel activeIndex={index} onSelect={handleSelect}  className="d-block w-100" data-aos="zoom-in" style={{ marginTop: isPhone ? '80px' : 'auto' }}> 
      <Carousel.Item interval={3000}>
        <img className="d-block w-100" src={img4} alt="" />
      </Carousel.Item>
      <Carousel.Item interval={3000}>
        <img className="d-block w-100" src={img2} alt="" />
      </Carousel.Item>
      <Carousel.Item interval={3000}>
        <img className="d-block w-100" src={img3} alt="" />
      </Carousel.Item>
      {/* <Carousel.Item interval={3000}>
        <img className="d-block w-100" src={img1} alt="" />
      </Carousel.Item> */}
    </Carousel>
  );
}

export default Sliderimg;
