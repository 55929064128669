import React, { useState, useEffect } from "react";
import {
  Typography,
  Button,
  Modal,
  Paper,
  Grid,
  CircularProgress,
  Box,
} from "@mui/material";
import { db } from "../../../firebase";
import { collection, getDocs, query, orderBy } from "firebase/firestore";
import { toast } from "react-toastify";
import ManageSelectedOrder from "./manageSelectedOrder";

const ManageOrders = () => {
  const [orders, setOrders] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const fetchOrders = async () => {
      setLoading(true);
      try {
        const ordersCollection = collection(db, "Orders");
        const ordersSnapshot = await getDocs(
          query(ordersCollection, orderBy("createdAt", "desc"))
        );

        const allOrders = ordersSnapshot.docs.map((orderDoc) => ({
          orderId: orderDoc.id,
          ...orderDoc.data(),
        }));

        setOrders(allOrders);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching orders:", error);
        setLoading(false);
      }
    };

    fetchOrders();
  }, []);

  const handleShowOrder = (order) => {
    setSelectedOrder(order);
  };

  const handleCloseModal = () => {
    setSelectedOrder(null);
  };
  if (loading) return <CircularProgress />;
  return (
    <div>
      {orders.length === 0 && !loading && (
        <Typography>No orders found !</Typography>
      )}
      {orders.map((order) => (
        <Paper
          key={order.razorpay_order_id}
          elevation={3}
          style={{ padding: "20px", marginBottom: "20px", textAlign: "center" }}
        >
          {order.trackingId && (
            <Typography sx={{ fontWeight: "bold", marginBottom: "10px" }}>
              Tracking ID: {order.trackingId}
            </Typography>
          )}
          <Typography variant="h6" gutterBottom>
            <b>{order.userName}</b>
          </Typography>
          <Typography variant="body1" gutterBottom>
            {order.razorpay_order_id}
          </Typography>
          <Typography variant="body2">
            <b>{order.createdAt.toDate().toDateString()}</b>{" "}
          </Typography>
          <br />
          <Button
            onClick={() => handleShowOrder(order)}
            variant="contained"
            style={{ backgroundColor: "#7A1B1C", color: "white" }}
          >
            Show Order
          </Button>
        </Paper>
      ))}

      <Modal open={!!selectedOrder} onClose={handleCloseModal}>
        <Box sx={{ ...style, width: 400 }}>
          <Typography variant="h5" color="#7A1B1C">
            <b>Order Details</b>
          </Typography>
          <hr />
          {selectedOrder && (
            <div style={{ color: "#7A1B1C" }}>
              <ManageSelectedOrder {...selectedOrder} />
              <hr />
            </div>
          )}
          <div style={{ textAlign: "center" }}>
            <Button
              onClick={handleCloseModal}
              variant="contained"
              style={{ backgroundColor: "#7A1B1C", color: "white" }}
            >
              Close
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};
export default ManageOrders;
