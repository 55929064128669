import React, { useState } from 'react';
import { Box, Typography, TextField, Button, TextareaAutosize, Modal, Paper, MenuItem, Select, FormControl, InputLabel } from '@mui/material';
import categories from '../../../assets/Categories/categories';
import { addDoc, collection } from 'firebase/firestore';
import { storage, db } from '../../../firebase';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { toast } from 'react-toastify';

function AddProduct({ handleCloseModal, setProducts, products }) {
  const [productName, setProductName] = useState('');
  const [price, setPrice] = useState('');
  const [maxPrice, setMaxPrice] = useState('');
  const [weight, setWeight] = useState('');
  const [features, setFeatures] = useState([]);
  const [productDescription, setProductDescription] = useState('');
  const [imageFiles, setImageFiles] = useState([]);
  const [isManageImagesModalOpen, setIsManageImagesModalOpen] = useState(false);
  const [category, setCategory] = useState('');
  const handleAddNewImages = (e) => {
    const files = e.target.files;
    setImageFiles([...imageFiles, ...files]);
  };

  const handleDeleteImage = (index) => {
    const updatedImageFiles = [...imageFiles];
    updatedImageFiles.splice(index, 1);
    setImageFiles(updatedImageFiles);
  };

  const handleManageImages = () => {
    setIsManageImagesModalOpen(true);
  };

  const handleCloseManageImagesModal = () => {
    setIsManageImagesModalOpen(false);
  };

  const handleAddProduct = async () => {
    try {
      // Upload images to Firebase Storage
      const imageURLs = await uploadImages(imageFiles);

      // Create a new product document
      const words = productName.split(' ');
      const lowerCaseWords = words.map((word) => word.toLowerCase());
      const prefixes = lowerCaseWords.flatMap((word) => {
        return Array.from({ length: word.length }, (_, i) => word.slice(0, i + 1));
      });

      const newProduct = {
        productName: productName,
        category: category,
        price: price,
        maxPrice: maxPrice,
        weight: weight,
        features: features,
        description: productDescription,
        imageURLs: imageURLs,
        nameContains: prefixes,
      };

      const docRef = await addDoc(collection(db, 'Products'), newProduct);
      toast.success('Product added successfully');

      // Refresh the product list after adding a new product
      setProducts([...products, { productId: docRef.id, ...newProduct }]);

      // Clear the form fields after adding a new product
      setProductName('');
      setPrice('');
      setMaxPrice('');
      setWeight('');
      setFeatures([]);
      setProductDescription('');
      setImageFiles([]);
      setCategory('');

      handleCloseModal();
    } catch (error) {
      console.error('Error adding product:', error.message);
      toast.error('Error adding product');
    }
  };

  const uploadImages = async (files) => {
    const uploadTasks = files.map((file) => {
      return new Promise(async (resolve, reject) => {
        try {
          const imageRef = ref(storage, `product_images/${file.name}`);
          const uploadTask = uploadBytesResumable(imageRef, file);

          uploadTask.on(
            'state_changed',
            (snapshot) => {
              const percent = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
              // You can update UI with the upload progress if needed
            },
            (err) => {
              console.error(err);
              reject(err);
            },
            async () => {
              try {
                const url = await getDownloadURL(uploadTask.snapshot.ref);
                resolve(url);
              } catch (error) {
                console.error(error);
                reject(error);
              }
            }
          );
        } catch (error) {
          console.error('Error uploading images:', error);
          reject(error);
        }
      });
    });

    try {
      const uploadedImageURLs = await Promise.all(uploadTasks);
      return uploadedImageURLs;
    } catch (error) {
      console.error('Error uploading images:', error);
      return [];
    }
  };

  return (
    <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', bgcolor: 'background.paper', boxShadow: 24, p: 4, width: 400, borderRadius: "2%" }}>
      <Typography variant="h5">Add New Product</Typography>
      <TextField
        label="Product Name"
        variant="outlined"
        value={productName}
        onChange={(e) => setProductName(e.target.value)}
        style={{ marginTop: '10px', marginBottom: '10px' }}
      />
      <TextField
        label="Price"
        variant="outlined"
        value={price}
        onChange={(e) => setPrice(e.target.value)}
        style={{ marginTop: '10px', marginBottom: '10px' }}
      />
      <TextField
        label="Maximum Price"
        variant="outlined"
        value={maxPrice}
        onChange={(e) => setMaxPrice(e.target.value)}
        style={{ marginTop: '10px', marginBottom: '10px' }}
      />
      <TextField
        label="Weight (g)"
        variant="outlined"
        value={weight}
        onChange={(e) => setWeight(e.target.value)}
        style={{ marginTop: '10px', marginBottom: '10px' }}
      />
      <FormControl style={{ marginTop: '10px', marginBottom: '10px', width: '100%' }}>
        <InputLabel id="category-label">Category</InputLabel>
        <Select
          labelId="category-label"
          label="Category"
          value={category}
          onChange={(e) => setCategory(e.target.value)}
        >
          {categories.map((category) => (
            <MenuItem key={category.value} value={category.value}>
              {category.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <TextareaAutosize
        minRows={3} 
        maxRows={10} 
        placeholder="Features ('*' separated)"
        value={features.join('* ')}
        onChange={(e) => setFeatures(e.target.value.split('*').map(feature => feature))}
        style={{ marginTop: '10px', marginBottom: '10px', width: '100%', resize: 'none' }}
      />
      <TextareaAutosize
        rows={3}
        maxRows={10}
        placeholder="Description"
        value={productDescription}
        onChange={(e) => setProductDescription(e.target.value)}
        style={{ marginTop: '10px', marginBottom: '10px', width: '100%' }}
      />
      <Button variant="outlined" onClick={handleManageImages}>
        Manage Images
      </Button>
      <Button variant="outlined" color="primary" onClick={handleAddProduct}>
        + Add Product
      </Button>
      <Button onClick={handleCloseModal}>Close</Button>

      {/* Manage Images Modal */}
      <Modal open={isManageImagesModalOpen} onClose={handleCloseManageImagesModal}>
        <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', bgcolor: 'background.paper', boxShadow: 24, p: 4, width: 400 }}>
          <Typography variant="h5">Manage Images</Typography>
          <div>
            {imageFiles.map((file, index) => (
              <Paper key={index} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '8px', marginBottom: '8px' }}>
                <img src={URL.createObjectURL(file)} alt={`New Product Image ${index}`} style={{ width: '50px', height: '50px', marginRight: '8px' }} />
                <Button variant="outlined" onClick={() => handleDeleteImage(index)}>
                  Delete
                </Button>
              </Paper>
            ))}
            <input type="file" multiple onChange={handleAddNewImages} />
          </div>
          <Button onClick={handleCloseManageImagesModal}>Save</Button>
        </Box>
      </Modal>
    </Box>
  );
}

export default AddProduct;
