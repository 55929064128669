import React, { useState } from 'react';
import { Button, TextField, Typography, Rating, CardContent, Card } from '@mui/material';
import { addDoc, collection } from 'firebase/firestore';
import { db } from '../../firebase'; // Import your Firebase configuration
import { app } from '../../firebase';
import { getAuth } from 'firebase/auth';
import { useAuthState } from 'react-firebase-hooks/auth';

const auth = getAuth(app);

const ProductPostReview = ({ productId, updateReviews, userName }) => {
  const [user] = useAuthState(auth);

  
  const userId = user.uid;
  const [rating, setRating] = useState(0); // Default rating
  const [reviewText, setReviewText] = useState('');
  
  const handleRatingChange = (newRating) => {
    setRating(newRating);
  };

  const handleReviewTextChange = (event) => {
    setReviewText(event.target.value);
  };

  const handleSubmit = async () => {
    // Validate the form fields (add more validation if needed)
    if (!reviewText.trim()) {
      alert('Please enter your review before submitting.');
      return;
    }
    
    try {
      const review = {
        rating: parseInt(rating, 10),
        review: reviewText.trim(),
        timestamp: Date.now(),
        userId: userId,
        userName: userName
      };
      const reviewsCollectionRef = collection(db, 'Products', productId, 'Reviews');
      await addDoc(reviewsCollectionRef, review);
      
      // Clear the form fields after submission
      setRating(1);
      setReviewText('');
      updateReviews();
      alert('Review submitted successfully!');
    } catch (error) {
      console.error('Error submitting review:', error.message);
      alert('An error occurred while submitting the review. Please try again later.');
    }
  };
  

  return (
    <Card>
      <CardContent>
        <Typography variant="h6" component="div" mb={1} color="#7A1B1C" style={{fontWeight: "bolder"}}>
          Post a Review
        </Typography>
        <div>
          <Rating
            name="rating"
            value={rating}
            onChange={(event, newRating) => handleRatingChange(newRating)}
            precision={1} // Set precision to 1 to have whole stars
          />
        </div>
        <div>
          <TextField
            label="Review"
            multiline
            rows={4}
            fullWidth
            value={reviewText}
            onChange={handleReviewTextChange}
            variant="outlined"
            sx={{ mb: 2 }}
          />
        </div>
        <div>
          <Button variant="contained" fullWidth onClick={handleSubmit} style={{background: "linear-gradient(90deg, rgba(87,33,33,1) 0%, rgba(0,0,0,1) 100%, rgba(0,0,0,1) 100%)"}}>
            Submit Review
          </Button>
        </div>

      </CardContent>
    </Card>
  );
};

export default ProductPostReview;
