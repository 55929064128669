import React, { useState } from 'react';
import { Button, CircularProgress, Modal, Box, Typography } from '@mui/material';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from '../../firebase';

function CashOnDelivery({ deliveryCharge, totalPrice, clearCart, shippingAddress, shippingName, shippingPhone }) {
  const [modalOpen, setModalOpen] = useState(false);
  const navigate = useNavigate();

  const checkOrderIdExists = async (orderIdToCheck) => {
    try {
      const ordersCollection = collection(db, 'Orders');
      const orderSnapshot = await getDocs(query(ordersCollection, where('razorpay_order_id', '==', orderIdToCheck)));
      return !orderSnapshot.empty;
    } catch (error) {
      console.error('Error checking order ID existence:', error);
      // In case of an error, return false to avoid processing the order
      return false;
    }
  };

  const handleConfirmOrder = async () => {
    if (!shippingAddress || !shippingName || !shippingPhone) {
      toast.error('Please fill in all shipping details before proceeding to checkout.');
      return;
    }

    let orderId;
    do {
      orderId = 'COD' + Math.floor(Math.random() * 100000000);
    } while (await checkOrderIdExists(orderId));

    const paymentId = "COD";
    clearCart(paymentId, orderId, shippingAddress, shippingName, shippingPhone);
    toast.success('Order Placed Successfully! Thank you for shopping with us.')
    navigate('/orders');
  };

  return (
    <React.Fragment>
      <Button
        variant="contained"
        color="primary"
        fullWidth
        sx={{ marginTop: 2 }}
        onClick={() => setModalOpen(true)}
      >
        Cash On Delivery
      </Button>
      <Modal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        aria-labelledby="confirm-order-modal"
        aria-describedby="modal-to-confirm-order"
      >
        <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', bgcolor: 'background.paper', boxShadow: 24, p: 4, width: 400, borderRadius: "8px" }}>
          <Typography id="modal-modal-title" variant="h6" component="h2" style={{ color: "#7A1B1C" }}>
            <b>Confirm Order !</b>
          </Typography>
          <hr />
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <b>Total Price to be paid: </b>  &#x20B9;{ (totalPrice + (18 / 100) * totalPrice).toFixed(0)} <br />
            <b>Shipping Name:</b> {shippingName} <br />
            <b>Shipping Phone:</b> {shippingPhone} <br />
            <b>Shipping Address: </b>{shippingAddress}
            <hr />
            <b>Are you sure you want to confirm this order?</b>
          </Typography>
          <Button onClick={handleConfirmOrder} sx={{ mt: 2 }}>
            <b>Confirm</b>
          </Button>
          <Button onClick={() => setModalOpen(false)} sx={{ mt: 2, ml: 2 }}>
            <b>Cancel</b>
          </Button>
        </Box>
      </Modal>
    </React.Fragment>
  );
}

export default CashOnDelivery;
