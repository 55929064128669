import React from "react";
import { Link } from "react-router-dom";
import { Typography } from "@mui/material";
import placeholderImage from "../../../assets/images/infinityLogo.png"; // Import a placeholder image

const MobileProduct = ({ product }) => {
  const imageUrl =
    product.imageURLs && product.imageURLs.length > 0
      ? product.imageURLs[0]
      : placeholderImage;

  return (
    <div
      style={{
        display: "flex",
        marginBottom: "20px",
        width: "100%", // Make the container 100% wide
        justifyContent: "center", // Centers the content horizontally
      }}
    >
      <div
        style={{
          display: "flex",
          width: "90%", // Restricts the inner width to 90% of its parent
          maxWidth: "600px", // Optional: limits the maximum width to avoid overly wide layouts
          height: "162px",
          border: "1px solid #EEEEEE",
        }}
      >
        <div style={{ flex: "1", marginRight: "20px" }} data-aos="fade-up">
          <Link
            to={`/products/${product.id}`}
            style={{ textDecoration: "none", color: "black" }}
          >
            <img
              src={imageUrl}
              alt={`Product: ${product.productName}`}
              style={{
                height: "160px",
                maxWidth: "100%",
                minWidth: "100%",
              }}
            />
          </Link>
        </div>
        <div
          style={{ flex: "2", padding: "10px" }}
          data-aos="fade-up"
        >
          <Link
            to={`/products/${product.id}`}
            style={{ textDecoration: "none", color: "black" }}
          >
            <Typography
              variant="h6"
              gutterBottom
              sx={{ fontWeight: "bold", fontSize: "1em" }}
            >
              {product.productName}
            </Typography>
            <hr />
            <Typography variant="body1" gutterBottom>
              <div data-aos="flip-right">
                <span>
                  {product.maxPrice ? (
                    <span style={{ fontSize: "0.6em", color: "#291423" }}>
                      <strike>Rs. {product.maxPrice}</strike> &nbsp;
                    </span>
                  ) : (
                    <span style={{ fontSize: "0.6em", color: "#291423" }}>
                      <strike>Rs. {product.price}</strike> &nbsp;
                    </span>
                  )}
                </span>
                <span style={{ fontWeight: "bold", color: "#291423" }}>
                  Rs.{product.price}/-
                </span>{" "}
                <span style={{ backgroundColor: "#291423", paddingX: "8px" }}>
                  {product.maxPrice ? (
                    <span style={{ fontSize: "0.8em", color: "white" }}>
                      &nbsp;{" "}
                      {(
                        ((product.maxPrice - product.price) / product.maxPrice) *
                        100
                      ).toFixed(0)}
                      % Off &nbsp;
                    </span>
                  ) : (
                    <span style={{ fontSize: "0.8em", color: "white" }}>
                      &nbsp; 0% Off &nbsp;
                    </span>
                  )}
                </span>
              </div>
            </Typography>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default MobileProduct;
