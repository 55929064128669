import React from 'react';
import { Grid , Card, CardContent, Typography, Container} from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import Slider from "./components/sliderimg";
import Products from './components/products';
import DeliveryAd from "../../Components/Delivery_Ad/delivery_ad";
import Features from './components/features';
import CategoriesIcons from "./components/categoriesIcons";

function Home() {
  return (
    <>
      <Slider/>
      <Grid  sx={{ marginX: "auto", maxWidth: "1440px" }}>
        <CssBaseline />
          <div>
            {/* <DeliveryAd /> */}
            <CategoriesIcons />
            <Features/>
            <Products /> 
          </div>
            
      </Grid>
      {/* </Container> */}
    </>
  );
}

export default Home;
 