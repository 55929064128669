import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import categories from '../../../assets/Categories/categories';
import { Typography, Grid } from '@mui/material';

const CategoriesIcons = () => {
  const navigate = useNavigate();
  const [screenWidth, setScreenWidth] = useState(window.innerWidth - 53);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const handleCategoryClick = (category) => {
    navigate(`/category/query=${category}`);
  };

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth - 60);
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Grid  component="main" sx={{width: screenWidth, marginX: 'auto', maxWidth:"1444px" }}>
      <Typography variant="h6" gutterBottom sx={{ 
        marginTop: '35px', 
        background: "rgb(87,33,33)",
        background: "linear-gradient(90deg, rgba(31,10,10,1) 19%, rgba(59,39,80,1) 100%)",
        padding: '10px', 
        borderRadius: '5px', 
        color: "white",
        fontWeight: "bold",
        fontSize: "14px",
        marginBottom: '0px',
        width: "100%",
        paddingX: "30px",
        boxShadow: "rgb(38, 57, 77) 0px 20px 30px -10px",
      }}>
        <div data-aos="zoom-in">
          Top Categories
        </div>
      </Typography>
      <div className={`categories-container thinbar ${isMobile ? 'hide-scrollbar' : ''}`} style={{ 
        display: 'flex', 
        flexDirection: 'row', 
        overflowX: 'scroll', 
        padding: '10px' 
      }}>
        {categories.map((category) => (
          <div
            key={category.value}
            className="category-icon"
            onClick={() => handleCategoryClick(category.value)}
            style={{ marginRight: '10px' }}
          >
            <div className="card" style={{ 
              display: 'flex', 
              flexDirection: 'column', 
              alignItems: 'center',
              height: isMobile ? '160px' : '200px',
            }}>
              <div className="icon">
                <img src={category.logo} alt={category.label} height={isMobile ? 60 : 100} /> 
              </div>
              <div className="card__body" height={isMobile ? 160 : 200} >
                <h6 style={{ fontSize: isMobile ? '10px' : '14px' }}>{category.label}</h6>
              </div>
              <span style={{ fontSize: isMobile ? '10px' : '14px' }}>{category.label}</span> 
            </div>
          </div>
        ))}
      </div>
    </Grid>
  );
};

export default CategoriesIcons;