import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Grid, CircularProgress, Typography } from "@mui/material";
import { db } from "../../firebase";
import { collection, query, where, getDocs } from "firebase/firestore";
import Product from "../Products/components/product";

function SearchResults() {
  const location = useLocation();
  const [searchResults, setSearchResults] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchSearchResults = async () => {
      const searchQuery = decodeURIComponent(location.pathname.split("=")[1]);
      const searchWords = searchQuery.split(' ');

      const itemsCollection = collection(db, "Products");
      let results = [];
      for (const word of searchWords) {
        const q = query(
          itemsCollection,
          where("nameContains", "array-contains", word)
        );
        const querySnapshot = await getDocs(q);
  
        querySnapshot.forEach((doc) => {
          const productData = {
            id: doc.id,
            ...doc.data(),
          };
          if (!results.some((result) => result.id === productData.id)) {
            results.push(productData);
          }
        });
      }
      setSearchResults(results);
      setIsLoading(false);
    };

    fetchSearchResults();
  }, [location.pathname]);

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",  
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  return (
    <div>
      <div>
        <Typography
          variant="h6"
          gutterBottom
          sx={{
            marginTop: "35px",
            background: "rgb(87,33,33)",
            background:
              "linear-gradient(90deg, rgba(31,10,10,1) 19%, rgba(59,39,80,1) 100%)",
            padding: "10px",
            borderRadius: "5px",
            color: "white",
            fontWeight: "bold",
            fontSize: "14px",
            marginBottom: "0px",
            paddingX: "30px",
            boxShadow: "rgb(38, 57, 77) 0px 20px 30px -10px",
          }}
        >
          <div data-aos="zoom-in">
            Search Results for "
            {decodeURIComponent(location.pathname.split("=")[1])}"
          </div>
        </Typography>
      </div>
      {searchResults.length === 0 ? (
        <Typography
          variant="subtitle1"
          sx={{
            marginTop: "20px",
            textAlign: "center",
            fontSize: "18px",
            fontWeight: "bold",
          }}
        >
          No results found. Try other keywords.
        </Typography>
      ) : (
        <Grid container spacing={2} mt={2}>
          {searchResults.map((product) => (
            <Product key={product.id} product={product} />
          ))}
        </Grid>
      )}
    </div>
  );
}

export default SearchResults;
