import React from 'react';
import { Typography, CssBaseline } from '@mui/material';

function About() {
  return (
    <div>
      <CssBaseline />
        <hr />
        <Typography>
          At Infinity Adda, we're more than just an e-commerce website – we're a hub for electronic and electric components enthusiasts. I'm Shivam Mishra, and I'm thrilled to guide you through our universe of innovation and technology.
        </Typography>
        <Typography>
          Explore our extensive collection of electronic components, from cutting-edge gadgets to essential components for your DIY projects. Whether you're a seasoned engineer or a hobbyist, Infinity Adda is designed to meet all your needs.
        </Typography>
        <Typography>
          Connect with us and be part of a community that shares a passion for innovation. If you have any questions, product inquiries, or just want to chat about the latest tech trends, feel free to reach out. You can email me at <a href="mailto:shivammishracreator2@gmail.com">shivammishracreator2@gmail.com</a> or give me a call at +91 7879070809.
        </Typography>
        <Typography>
          Thank you for choosing Infinity Adda – where technology meets community, and possibilities are infinite. Let's build a future together!
        </Typography>
        <br />
    </div>
  );
}

export default About;
