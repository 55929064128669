import React, { useState} from 'react';
import { Button } from '@mui/material';
import { addDoc, collection } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { db } from '../../firebase';
import { toast } from 'react-toastify';

const AddToCart = ({ productId, isMobile }) => {

  const addToCart = async () => {
    const { currentUser } = getAuth();
  
    if (!currentUser) {
      toast.error('User not logged in.');
      console.error('User not logged in.');
      return;
    }
  
    try {
      const order = {
        productId: productId,
        quantity: 1,
      };
      const userCartRef = collection(db, 'users', currentUser.uid, 'cart');
      await addDoc(userCartRef, order);
      toast.success('Added to cart successfully!');
    } catch (error) {
      toast.error('Error adding to cart');
      console.error('Error adding to cart:', error.message);
    }
  };


  return (
    <div style={{padding: isMobile? '0px' : '20px'}}>
      <Button variant="contained" style={{background: "linear-gradient(90deg, rgba(87,33,33,1) 0%, rgba(0,0,0,1) 100%, rgba(0,0,0,1) 100%)"}} fullWidth  onClick={addToCart}>
          <div data-aos="flip-left">
          Add to Cart
          </div>
      </Button>
    </div>
  );
};

export default AddToCart;
