import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Grid, CircularProgress, Typography } from '@mui/material';
import { db } from '../../firebase';
import { collection, query, where, getDocs } from 'firebase/firestore';
import Product from '../Products/components/product';

function FilterResult() {
  const location = useLocation();
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const category = decodeURIComponent(location.pathname.split('=')[1]);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const itemsCollection = collection(db, 'Products');
        const q = query(
          itemsCollection,
          where('category', '==', category)
        );
        const querySnapshot = await getDocs(q);
        const products = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setProducts(products);
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching products:', error);
        setIsLoading(false);
        setIsError(true);
      }
    };

    fetchProducts();
  }, [category]);

  if (isLoading) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  if (isError) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <Typography variant="h6" color="error">
          Error fetching products. Please try again later.
        </Typography>
      </div>
    );
  }

  return (
    <div>
      <Typography
        variant="h6"
        gutterBottom
        sx={{
          marginTop: "35px",
          background: "rgb(87,33,33)",
          background:
            "linear-gradient(90deg, rgba(31,10,10,1) 19%, rgba(59,39,80,1) 100%)",
          padding: "10px",
          borderRadius: "5px",
          color: "white",
          fontWeight: "bold",
          fontSize: "14px",
          marginBottom: "0px",
          paddingX: "30px",
          boxShadow: "rgb(38, 57, 77) 0px 20px 30px -10px",
        }}
      >
        <div data-aos="zoom-in">
          {category}
        </div>
      </Typography>
      {products.length === 0 ? (
        <Typography
          variant="subtitle1"
          sx={{
            marginTop: "20px",
            textAlign: "center",
            fontSize: "18px",
            fontWeight: "bold",
          }}
        >
          No products found for {category}.
        </Typography>
      ) : (
        <Grid container spacing={2} mt={2}>
          {products.map((product) => (
            <Product key={product.id} product={product} />
          ))}
        </Grid>
      )}
    </div>
  );
}

export default FilterResult;
