import React, { useState, useEffect } from 'react';
import { getAuth } from 'firebase/auth';
import { getFirestore, updateDoc, doc, getDoc } from 'firebase/firestore';
import { toast } from 'react-toastify';
import {
  Grid,
  TextField,
  Button,
  Typography,
  Container,
  CssBaseline,
  CircularProgress,
} from '@mui/material';

const Profile = () => {
  const auth = getAuth();
  const db = getFirestore();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    address: '',
    phoneNumber: '',
    alternatePhoneNumber: '', 
  });

  useEffect(() => {
    const fetchUserData = async () => {
      if (auth.currentUser) {
        try {
          const userDocRef = doc(db, 'users', auth.currentUser.uid);
          const userDoc = await getDoc(userDocRef);

          if (userDoc.exists()) {
            const userData = userDoc.data();
            setFormData({
              firstName: userData.firstName || '',
              lastName: userData.lastName || '',
              address: userData.address || '',
              phoneNumber: userData.phoneNumber || '',
              alternatePhoneNumber: userData.alternatePhoneNumber || '',
            });
          }
        } catch (error) {
          toast.error('Error fetching user data:', error.message);
        }
      }
    };

    fetchUserData();
  }, [auth.currentUser, db]);

  const handleChange = (field) => (event) => {
    setFormData({ ...formData, [field]: event.target.value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      setLoading(true);

      if (auth.currentUser) {
        const userDocRef = doc(db, 'users', auth.currentUser.uid);
        await updateDoc(userDocRef, {
          firstName: formData.firstName,
          lastName: formData.lastName,
          address: formData.address,
          phoneNumber: formData.phoneNumber,
          alternatePhoneNumber: formData.alternatePhoneNumber,
        });

        toast.success('Profile updated successfully!');
      }
    } catch (error) {
      toast.error('Error updating profile:', error.message);
      console.error('Error updating profile:', error.message);
    } finally {
      setLoading(false);
    }
  };

  if (!auth.currentUser) {
    return (
      <Typography variant="body1" component="div">
        User not logged in.
      </Typography>
    );
  }

  return (
    <Grid container component="main" sx={{  marginTop: '40px' }}>
      <Container component="main" maxWidth="xs" style=
      {{
        backgroundColor: "white",
        padding : "20px",
        borderRadius: "2%",
        boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px",
      }}>
        <CssBaseline />
        <div>
          <Typography variant="h5" component="div" color="primary" textAlign={"center"}>
            Edit Profile
          </Typography>
          <hr />
          <form onSubmit={handleSubmit}>
            <TextField
              label="First Name"
              variant="outlined"
              fullWidth
              margin="normal"
              value={formData.firstName}
              onChange={handleChange('firstName')}
            />
            <TextField
              label="Last Name"
              variant="outlined"
              fullWidth
              margin="normal"
              value={formData.lastName}
              onChange={handleChange('lastName')}
            />
            <TextField
              label="Phone Number"
              variant="outlined"
              fullWidth
              margin="normal"
              value={formData.phoneNumber}
              onChange={handleChange('phoneNumber')}
            />
            <TextField
              label="Alternate Phone Number"
              variant="outlined"
              fullWidth
              margin="normal"
              value={formData.alternatePhoneNumber}
              onChange={handleChange('alternatePhoneNumber')}
            />
            <TextField
              multiline
              rows={2}
              maxRows={4}
              label="Address"
              variant="outlined"
              fullWidth
              margin="normal"
              value={formData.address}
              onChange={handleChange('address')}
            />
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              disabled={loading}
              style={{ marginTop: '16px' }}
            >
              {loading ? <CircularProgress size={24} /> : 'Save Changes'}
            </Button>
          </form>
        </div>
      </Container>
    </Grid>
  );
};

export default Profile;
