import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Grid,
  CircularProgress,
  CssBaseline,
} from '@mui/material';
import { collection, getDocs, query, getDoc, updateDoc, doc, deleteDoc, addDoc, setDoc } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { db } from '../../firebase';
import CartItem from './cartItem';
import Checkout from './checkOut';
import { toast } from 'react-toastify';
import emptyCart from '../../assets/images/emptyCart.png';
import cart from '../../assets/images/cart.png';

const Cart = () => {
  const [cartItems, setCartItems] = useState([]);
  const [loading, setLoading] = useState(false); 
  const { currentUser } = getAuth();
  useEffect(() => {
    const fetchCartItems = async () => {
      setLoading(true);
      if (currentUser) {
        try {
          const cartQuery = query(
            collection(db, 'users', currentUser.uid, 'cart')
          );
  
          const cartSnapshot = await getDocs(cartQuery);
          const cartData = await Promise.all(cartSnapshot.docs.map(async (order) => {
            const cartItem = order.data();
            const productData = await getDoc(doc(db, 'Products', cartItem.productId));
            if (!productData.exists()) {
              return {
                id: order.id,
                ...cartItem,
                product: null
              }
            }
            return {
              id: order.id,
              ...cartItem,
              product: productData
            };
          }));
          console.log(cartData);
          setCartItems(cartData);
        } catch (error) {
          console.error('Error fetching cart items:', error.message);
        } finally {
          setLoading(false);
        }
      }
    };
  
    fetchCartItems();
  }, [currentUser]);
  
  
  const clearCart = async (
    razorpay_payment_id,
    razorpay_order_id,
    shippingAddress,
    shippingName,
    shippingPhone
  ) => {
    try {
      const cartQuery = query(collection(db, 'users', currentUser.uid, 'cart'));
      const cartSnapshot = await getDocs(cartQuery);
  
      // Create an array to store promises for adding items to 'orders' collection
      const addToOrdersPromises = [];
  
      // Iterate through each cart item
      cartSnapshot.forEach(async (cartItemDoc) => {
        const cartItemData = cartItemDoc.data();
  
        // Step 1: Fetch product information
        const productDoc = await getDoc(
          doc(db, 'Products', cartItemData.productId)
        );
        const productData = productDoc.data();
  
        // Generate a unique ID for the order
        const orderId = cartItemDoc.id;
  
        // Step 2: Add the item to the 'orders' collection
        const orderItemData = {
          productId: cartItemData.productId,
          productName: productData.productName,
          // Include other fields as needed
          userID: currentUser.uid,
          quantity: cartItemData.quantity,
          createdAt: new Date(),
          createdAtTimestamp: new Date().getTime(),
          razorpay_payment_id: razorpay_payment_id,
          razorpay_order_id: razorpay_order_id,
          userName: shippingName,
          userPhone: shippingPhone,
          shippingAddress: shippingAddress,
        };
  
        // Add a promise for adding the item to the 'orders' collection
        addToOrdersPromises.push(
          // Add document to 'users/orders' collection
          setDoc(doc(db, 'users', currentUser.uid, 'orders', orderId), orderItemData),
          // Add document to 'Orders' collection
          setDoc(doc(db, 'Orders', orderId), orderItemData)
        );
  
        // Step 3: Remove the item from the 'cart' collection
        const cartItemRef = doc(
          db,
          'users',
          currentUser.uid,
          'cart',
          cartItemDoc.id
        );
        await deleteDoc(cartItemRef);
      });
  
      // Wait for all promises to complete
      await Promise.all(addToOrdersPromises);
    } catch (error) {
      console.error('Error clearing cart:', error);
    }
  };
  
  
  
  const handleQuantityChange = async (cartItemId, newQuantity) => {
    try {
      const cartItemRef = doc(db, 'users', currentUser.uid, 'cart', cartItemId);
      await updateDoc(cartItemRef, {
        'quantity': newQuantity
      });
      const updatedCartItems = cartItems.map(item => {
        if (item.id === cartItemId) {
          return { ...item, quantity: newQuantity };
        }
        return item;
      });
      setCartItems(updatedCartItems);
    } catch (error) {
      console.error('Error updating quantity:', error.message);
    }
  };
  const handleRemoveItem = async (cartItemId) => {
    try {
      const cartItemRef = doc(db, 'users', currentUser.uid, 'cart', cartItemId);
      await deleteDoc(cartItemRef);
      setCartItems((prevCartItems) =>
        prevCartItems.filter((item) => item.id !== cartItemId)
      );
      toast.success('Item removed from cart successfully!');
    } catch (error) {
      console.error('Error removing item from cart:', error.message);
    }
  };

  return (
    <Grid container component="main" sx={{  marginTop: '5vh', maxWidth: '1000px', marginX: 'auto' }}>
    <CssBaseline />
    <Grid  sx={{margin:"auto"}}>
      {loading && <CircularProgress />} 
    </Grid>
    <Grid container spacing={2}>
      <Checkout cartItems={cartItems} clearCart={clearCart}/>
        {cartItems.length === 0 ? (
            <div style={{margin: "auto", marginTop: "10px"}} data-aos="flip-right">
              <img src={emptyCart} alt="" width="300px" />
            </div>
        ) : (
          <>
            {cartItems.map((item) => (
              <Grid item key={item.id} xs={12} sm={6} md={4}>
                <div data-aos="zoom-in">
                  <CartItem item={item} onQuantityChange={handleQuantityChange} onRemove={handleRemoveItem} />  
                </div>
              </Grid>
            ))}
            <div style={{margin: "auto"}} data-aos="flip-right">
              <img src={cart} alt="" width="300px" />
            </div>
          </>
      )}
    </Grid>
  </Grid>
  );
};

export default Cart;
