import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Grid, Card, CardContent, Typography, Button } from '@mui/material';
import Carousel from 'react-bootstrap/Carousel';
import MobileProduct from './mobileProduct';
import "bootstrap/dist/css/bootstrap.min.css";

function Product({ product }) {
  const [isMobile, setIsMobile] = useState(false);
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); 
    };

    handleResize(); // Check on initial render

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  if (isMobile) {
    // Render MobileProduct component for mobile devices
    return <MobileProduct product={product} />;
  }

  return (
    <Grid item key={product.id} xs={12} sm={3} md={2.4} lg={2.4}>
      <Link key={product.id} to={`/products/${product.id}`} style={{ textDecoration: 'none' }}>
        <Card sx={{ display: 'flex', flexDirection: 'column', boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px"
        }}>
          <CardContent sx={{ flex: '1 1 auto', overflow: 'hidden', height: "290px", padding: "28px" }}>
            <Carousel activeIndex={index} onSelect={handleSelect} className="d-block w-100" data-aos="zoom-in">
              {product.imageURLs.map((imageUrl, index) => (
                <Carousel.Item interval={3000}>
                  <img
                    key={index}
                    src={imageUrl}
                    alt={`Product: ${product.productName}`}
                    height="200px"
                    width="250px"
                    sx={{ objectFit: 'cover', borderRadius: '8px', marginBottom: '10px' }}
                  />
                </Carousel.Item>
              ))}
            </Carousel>

            <div style={{ height: "4.8em", overflow: "hidden" }}>
              <Typography sx={{ fontWeight: "bolder", marginTop: '15px' }} component="div">
                {product.productName}
              </Typography>
            </div>
          </CardContent>
          <CardContent style={{ marginTop: "0px" }}>
            <hr />
            <div data-aos="fade-up">
              <Typography variant="body1" component="div" mt={1} sx={{ paddingX: "15px", positon: "relative", bottom: "60px", width: "100%", color: "white", marginTop: "0px", marginBottom: "10px", paddingY: "0px" }}>
                <div data-aos="flip-up">
                  <span>
                    {product.maxPrice ? (
                      <span style={{ fontSize: "0.7em", color: '#291423' }}>
                        <strike>Rs. {product.maxPrice}</strike> &nbsp;
                      </span>
                    ): (
                      <span style={{ fontSize: "0.7em", color: '#291423' }}>
                        <strike>Rs. {product.price}</strike> &nbsp;
                      </span>
                    )}
                  </span>
                  <span style={{ fontWeight: "bold", color: "#291423" }}>
                    Rs. {product.price}/-
                  </span>
                  <span style={{backgroundColor: "#291423", padding: "0px", borderRadius: "4px"}}>
                    {product.maxPrice ? (
                      <span style={{ fontSize: "0.8em", color: 'white' }}>
                        &nbsp; {(((product.maxPrice - product.price) / product.maxPrice) * 100).toFixed(0)}% Off  &nbsp;
                      </span>
                    ) : (
                      <span style={{ fontSize: "0.8em", color: 'white' }}>
                        &nbsp; 0% Off &nbsp;
                      </span>
                    )}
                  </span>
                </div>
              </Typography>
              <Link to={`/products/${product.id}`} style={{ textDecoration: 'none' }}>
                <Button variant="contained" className='customButton'>
                  BUY NOW
                </Button>
              </Link>
            </div>

          </CardContent>
        </Card>

      </Link>
    </Grid>
  );
}


export default Product;
