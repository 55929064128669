import React, { useState, useEffect } from 'react';
import {
  Typography,
  Button,
  Modal,
  Grid,
  CircularProgress,
  Box,
} from '@mui/material';
import { collection, getDocs, deleteDoc, doc } from 'firebase/firestore';
import { db } from '../../../firebase';
import { toast } from 'react-toastify';
import ManageProduct from './manageProduct';
import EditProduct from './editProduct';
import AddProduct from './addProduct';

function ManageProducts() {
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [loading, setLoading] = useState(false);
  const [productName, setProductName] = useState('');
  const [productDescription, setProductDescription] = useState('');
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [productToDelete, setProductToDelete] = useState(null);
  const [isAddProductModalOpen, setIsAddProductModalOpen] = useState(false); 

  useEffect(() => {
    const fetchProducts = async () => {
      setLoading(true);
      try {
        const productsCollection = collection(db, 'Products');
        const productsSnapshot = await getDocs(productsCollection);
        const allProducts = [];

        productsSnapshot.forEach((productDoc) => {
          allProducts.push({ productId: productDoc.id, ...productDoc.data() });
        });

        setProducts(allProducts);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    };
    fetchProducts();
  }, []);

  const handleEditProduct = (product) => {
    setSelectedProduct(product);
    setProductName(product.productName || '');
    setProductDescription(product.description || '');
  };

  const handleOpenDeleteModal = (product) => {
    setProductToDelete(product);
    setIsDeleteModalOpen(true);
  };

  const handleCloseDeleteModal = () => {
    setProductToDelete(null);
    setIsDeleteModalOpen(false);
  };

  const handleDeleteProduct = async (productId) => {
    try {
      await deleteDoc(doc(db, 'Products', productToDelete.productId));
      toast.success('Product deleted successfully');
      // Refresh the product list after deletion
      const updatedProducts = products.filter((product) => product.productId !== productToDelete.productId);
      setProducts(updatedProducts);
      // Close the delete modal
      handleCloseDeleteModal();
    } catch (error) {
      console.error('Error deleting product:', error.message);
      toast.error('Error deleting product');
    }
  };
  // Function to open AddProduct modal
  const handleOpenAddProductModal = () => {
    setIsAddProductModalOpen(true);
  };

  // Function to close AddProduct modal
  const handleCloseAddProductModal = () => {
    setIsAddProductModalOpen(false);
  };

  const handleCloseModal = () => {
    setSelectedProduct(null);
    setProductName('');
    setProductDescription('');
  };
  if (loading) {
    return <CircularProgress />;
  }
  return (
    <div style={{textAlign:"center"}}>
      <div style={{textAlign:"center"}}>
        <Button variant="contained" color="primary" onClick={handleOpenAddProductModal} sx={{marginBottom: "20px"}} >
           + Add Product
        </Button>    
      </div>
        {products.length === 0 && !loading && <Typography>No products found!</Typography>}
        {products.map((product) => (
          <ManageProduct product={product} handleEditProduct={handleEditProduct}  handleOpenDeleteModal={handleOpenDeleteModal}/>
        ))}

      <Modal open={!!selectedProduct} onClose={handleCloseModal} style={{overflow:"scroll"}}>
        <EditProduct
          selectedProduct={selectedProduct}
          handleCloseModal={handleCloseModal}
          products={products}
          setProducts={setProducts}
        />
      </Modal>

      <Modal open={isDeleteModalOpen} onClose={handleCloseDeleteModal}>
        <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', bgcolor: 'background.paper', boxShadow: 24, p: 4, width: 400 , borderRadius: "2%"  }}>
          <Typography variant="h5">Confirm Deletion</Typography>
          <Typography>Are you sure you want to delete this product?</Typography>
          <Button variant="outlined" color="primary" onClick={handleDeleteProduct}>
            Delete
          </Button>
          <Button onClick={handleCloseDeleteModal}>Cancel</Button>
        </Box>
      </Modal>

      <Modal open={isAddProductModalOpen} onClose={handleCloseAddProductModal} style={{overflow:"scroll"}}>
        <AddProduct
          handleCloseModal={handleCloseAddProductModal}
          setProducts={setProducts}
          products={products}
        />
      </Modal>
    </div>
  );
}

export default ManageProducts;
