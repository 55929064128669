import React, { useState } from 'react';
import { IconButton, Popover, List, ListItem, ListItemText } from '@mui/material';
import FilterIcon from '@mui/icons-material/FilterList';
import { useNavigate } from 'react-router-dom';
import categories from '../../../assets/Categories/categories';

function Filter() {
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();

  const handleFilterClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (category) => {
    if (category) {
      navigate(`/category/query=${encodeURIComponent(category)}`);
    }
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  return (
    <>
      <IconButton style={{ color: "white", marginLeft: "10px" }} onClick={handleFilterClick}>
        <FilterIcon />
      </IconButton>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={() => handleClose()}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        PaperProps={{
          sx: {
            backgroundColor: 'rgb(32, 42, 54, 0.94)',
            color: 'white',
          },
        }}
      >
        <List>
          {categories.map((category) => (
            <ListItem  key={category.value} onClick={() => handleClose(category.value)}   onMouseOver={(e) => e.currentTarget.style.backgroundColor = 'rgba(10, 10, 10, 0.9)'}
            onMouseOut={(e) => e.currentTarget.style.backgroundColor = 'transparent'}>
              <ListItemText primary={category.value} />
            </ListItem>
          ))}
        </List>
      </Popover>
    </>
  )
}

export default Filter
