import React from 'react';
import { Typography } from '@mui/material';

function PrivacyPolicy() {
  return (
    <div>
      <hr />
      <Typography>
        Welcome to InfinityAdda. This Privacy Policy outlines how we collect, use, and disclose your personal information when you use our website.
      </Typography>
      <br />

      <Typography fontWeight="bold">
        1. Information We Collect
      </Typography>
      <Typography>
        We may collect various types of information, including personal and non-personal data, when you use InfinityAdda.
      </Typography>
      <br />

      <Typography fontWeight="bold">
        2. Use of Information
      </Typography>
      <Typography>
        The information we collect is used to provide, maintain, and improve our services. We may also use it to communicate with you and personalize your experience.
      </Typography>
      <br />

      <Typography fontWeight="bold">
        3. Disclosure of Information
      </Typography>
      <Typography>
        We may share your information with third-party service providers for various purposes, including analytics, marketing, and customer support. However, we do not sell your personal information to third parties.
      </Typography>
      <br />

      <Typography fontWeight="bold">
        4. Cookies
      </Typography>
      <Typography>
        We use cookies and similar technologies to collect information about your browsing behavior and preferences.
      </Typography>
      <br />

      <Typography fontWeight="bold">
        5. Security
      </Typography>
      <Typography>
        We implement reasonable security measures to protect your information. However, no method of transmission over the internet or electronic storage is entirely secure.
      </Typography>
      <br />

      <Typography fontWeight="bold">
        6. Your Choices
      </Typography>
      <Typography>
        You have the right to access, correct, or delete your personal information. You may also opt-out of certain communications and cookies.
      </Typography>
      <br />

      <Typography fontWeight="bold">
        7. Changes to Privacy Policy
      </Typography>
      <Typography>
        We reserve the right to modify or update this Privacy Policy at any time. Changes will be effective immediately upon posting to the website.
      </Typography>
    </div>
  );
}

export default PrivacyPolicy;
