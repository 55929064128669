// OrderItem.js

import React from 'react';
import {
  Card,
  CardContent,
  Typography,
  Grid,
} from '@mui/material';
import OrderItemDetails from './OrderItemDetails';

const OrderItem = ({ order }) => {
  return (
    <Card sx={{ marginBottom: 2 }}>
      <CardContent>
        <Grid container justifyContent="space-between" alignItems="center">
          {order.product ? (
            <>
              <Typography variant="h6" component="div">
                {order.product.data().productName}
                {order.trackingId && (
                  <Typography sx={{ fontWeight: 'bolder' }}>
                    Tracking ID: {order.trackingId}
                  </Typography>
                )}
              </Typography>
              <Grid
                xs={12}
                justify="space-between"
                style={{
                  backgroundImage: `url(${order.product.data().imageURLs[0]})`,
                  height: '300px',
                  marginTop: 20,
                  backgroundSize: 'cover',
                }}
              ></Grid>
              <OrderItemDetails order={order} />
            </>
          ) : (
            <>
              <Typography variant="h6" component="div">
                {order.productName} (Product not listed)
              </Typography>
              <Typography variant="body" component="div">
                Order Id: {order.id}
              </Typography>
              <Typography variant="h6" component="div">
                {order.trackingId && (
                  <Typography sx={{ fontWeight: 'bolder' }}>
                    Tracking ID: {order.trackingId}
                  </Typography>
                )}
              </Typography>
              {order.trackingId && (
                <Typography sx={{ fontWeight: 'bolder' }}>
                  Tracking ID: {order.trackingId}
                </Typography>
              )}
              <OrderItemDetails order={order} />
            </>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default OrderItem;
