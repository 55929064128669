import React, { useState } from "react";
import { Typography, Button, TextField } from "@mui/material";
import { updateDoc, doc } from "firebase/firestore";
import { db } from "../../../firebase";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

function ManageSelectedOrder(selectedOrder) {
  const [trackingId, setTrackingId] = useState("");
  console.log(selectedOrder.orderId, selectedOrder.userID)
  const handleTrackingIdChange = (e) => {
    setTrackingId(e.target.value);
  };
  const handleAddTrackingId = async (userId, orderId, trackingId) => {
    try {
      console.log(userId, orderId, trackingId)
      const orderDocRef = doc(db, 'users', userId, 'orders', orderId);
      const OrderDocRef = doc(db, 'Orders', orderId);
      await updateDoc(orderDocRef, {
        trackingId
      });
      await updateDoc(OrderDocRef, {
        trackingId
      });
      toast.success('Tracking ID added successfully');
    } catch (error) {
      console.error('Error adding tracking ID:', error.message);
      toast.error('Error adding tracking ID');
    }
  }
  return (
    <>
      <Typography variant="body2" color="#7A1B1C">Order ID: {selectedOrder.razorpay_order_id}</Typography>
      <Typography variant="body2" color="#7A1B1C">Product ID: {selectedOrder.productId}</Typography>
      <Typography>Product: {selectedOrder.productName}</Typography>
      <Typography>Quantity: <b>{selectedOrder.quantity}</b> </Typography>
      <Typography color="#7A1B1C">Name: <b>{selectedOrder.userName}</b></Typography>
      <Typography>Shipping Phone: <b>{selectedOrder.userPhone}</b></Typography>
      <Typography>Shipping Address:{selectedOrder.shippingAddress}</Typography>
      <Typography>
        Created On: {selectedOrder.createdAt.toDate().toDateString()}
      </Typography>
      <Typography>
        Created At: {selectedOrder.createdAt.toDate().toLocaleTimeString()}
      </Typography>
      <Typography>Payment ID: {selectedOrder.razorpay_payment_id}</Typography>
      <hr />
      <TextField
        label="Tracking ID"
        variant="outlined"
        value={trackingId || selectedOrder.trackingId}
        onChange={handleTrackingIdChange}
        style={{ marginTop: "10px", marginBottom: "10px"}}
      />
      <Link
        to={`/products/${selectedOrder.productId}`}
        style={{ textDecoration: "none" }}
      >
        <Button variant="outlined" color="primary">
          View Product
        </Button>
      </Link>
      <Button
        variant="outlined"
        color="primary"
        onClick={() => handleAddTrackingId(selectedOrder.userID, selectedOrder.orderId, trackingId)}
        // style={{ marginTop: '10px' }}
      >
        Add Tracking ID
      </Button>
    </>
  );
}

export default ManageSelectedOrder;
