import React, { useState } from "react";
import {
  Grid,
  Box,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  IconButton,
} from "@mui/material";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import PaidIcon from "@mui/icons-material/Paid";
import PriceCheckIcon from "@mui/icons-material/PriceCheck";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import PhoneIcon from "@mui/icons-material/Phone";

const Features = () => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box
      sx={{
        mt: "20px",
        padding: "40px",
        background: "linear-gradient(135deg, #f8f9fa, #e6e8ec)",
        borderRadius: "12px",
        boxShadow: "0 8px 20px rgba(0,0,0,0.1)",
        maxWidth: "1444px",
        mx: "auto",
        "@media (max-width: 600px)": {
          padding: "20px",
        },
      }}
      data-aos="fade-up"
    >
      <Grid container spacing={4} justifyContent="center">
        {/* Support Agent Icon */}
        <Grid item xs={12} sm={2.4}>
          <Box
            textAlign="center"
            data-aos="flip-right"
            sx={{
              transition: "transform 0.3s ease-in-out",
              "&:hover": {
                transform: "scale(1.1)",
              },
              "@media (max-width: 600px)": {
                margin: "0 auto",
              },
            }}
            onClick={handleClickOpen}
          >
            <SupportAgentIcon
              sx={{
                fontSize: { xs: 80, sm: 60 },
                color: "#291423",
                boxShadow: "0 4px 12px rgba(0,0,0,0.1)",
                borderRadius: "50%",
                padding: "10px",
                backgroundColor: "#fff",
                transition: "all 0.3s ease-in-out",
                "&:hover": {
                  boxShadow: "0 0 20px 10px rgba(255, 99, 71, 0.5)",
                },
              }}
            />
            <Typography
              variant="h6"
              gutterBottom
              sx={{ mt: 2, fontWeight: 600, color: "#333" }}
            >
              For customized orders, contact us anytime
            </Typography>
          </Box>
        </Grid>

        {/* Modal Dialog */}
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="contact-dialog-title"
        >
          <DialogTitle id="contact-dialog-title">Contact Us</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Call us for customized orders at <strong>7879070809</strong>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button
              href="tel:7879070809"
              color="primary"
              variant="contained"
              startIcon={<PhoneIcon />}
            >
              Call Now
            </Button>
          </DialogActions>
        </Dialog>

        {/* Local Shipping Icon */}
        <Grid item xs={12} sm={2.4}>
          <Box
            textAlign="center"
            data-aos="flip-right"
            sx={{
              transition: "transform 0.3s ease-in-out",
              "&:hover": {
                transform: "scale(1.1)",
              },
              "@media (max-width: 600px)": {
                margin: "0 auto",
              },
            }}
          >
            <LocalShippingIcon
              sx={{
                fontSize: { xs: 80, sm: 60 },
                color: "#291423",
                boxShadow: "0 4px 12px rgba(0,0,0,0.1)",
                borderRadius: "50%",
                padding: "10px",
                backgroundColor: "#fff",
                transition: "all 0.3s ease-in-out",
                "&:hover": {
                  boxShadow: "0 0 20px 10px rgba(255, 99, 71, 0.5)",
                },
              }}
            />
            <Typography
              variant="h6"
              gutterBottom
              sx={{ mt: 2, fontWeight: 600, color: "#333" }}
            >
              Convenient Delivery Options
            </Typography>
          </Box>
        </Grid>

        {/* Paid Icon */}
        <Grid item xs={12} sm={2.4}>
          <Box
            textAlign="center"
            data-aos="flip-right"
            sx={{
              transition: "transform 0.3s ease-in-out",
              "&:hover": {
                transform: "scale(1.1)",
              },
              "@media (max-width: 600px)": {
                margin: "0 auto",
              },
            }}
          >
            <PaidIcon
              sx={{
                fontSize: { xs: 80, sm: 60 },
                color: "#291423",
                boxShadow: "0 4px 12px rgba(0,0,0,0.1)",
                borderRadius: "50%",
                padding: "10px",
                backgroundColor: "#fff",
                transition: "all 0.3s ease-in-out",
                "&:hover": {
                  boxShadow: "0 0 20px 10px rgba(255, 99, 71, 0.5)",
                },
              }}
            />
            <Typography
              variant="h6"
              gutterBottom
              sx={{ mt: 2, fontWeight: 600, color: "#333" }}
            >
              Cash on Delivery Available
            </Typography>
          </Box>
        </Grid>

        {/* Price Check Icon */}
        <Grid item xs={12} sm={2.4}>
          <Box
            textAlign="center"
            data-aos="flip-right"
            sx={{
              transition: "transform 0.3s ease-in-out",
              "&:hover": {
                transform: "scale(1.1)",
              },
              "@media (max-width: 600px)": {
                margin: "0 auto",
              },
            }}
          >
            <PriceCheckIcon
              sx={{
                fontSize: { xs: 80, sm: 60 },
                color: "#291423",
                boxShadow: "0 4px 12px rgba(0,0,0,0.1)",
                borderRadius: "50%",
                padding: "10px",
                backgroundColor: "#fff",
                transition: "all 0.3s ease-in-out",
                "&:hover": {
                  boxShadow: "0 0 20px 10px rgba(255, 99, 71, 0.5)",
                },
              }}
            />
            <Typography
              variant="h6"
              gutterBottom
              sx={{ mt: 2, fontWeight: 600, color: "#333" }}
            >
              Unbeatable Prices Guaranteed
            </Typography>
          </Box>
        </Grid>

        {/* Access Time Icon */}
        <Grid item xs={12} sm={2.4}>
          <Box
            textAlign="center"
            data-aos="flip-right"
            sx={{
              transition: "transform 0.3s ease-in-out",
              "&:hover": {
                transform: "scale(1.1)",
              },
              "@media (max-width: 600px)": {
                margin: "0 auto",
              },
            }}
          >
            <AccessTimeIcon
              sx={{
                fontSize: { xs: 80, sm: 60 },
                color: "#291423",
                boxShadow: "0 4px 12px rgba(0,0,0,0.1)",
                borderRadius: "50%",
                padding: "10px",
                backgroundColor: "#fff",
                transition: "all 0.3s ease-in-out",
                "&:hover": {
                  boxShadow: "0 0 20px 10px rgba(255, 99, 71, 0.5)",
                },
              }}
            />
            <Typography
              variant="h6"
              gutterBottom
              sx={{ mt: 2, fontWeight: 600, color: "#333" }}
            >
              24/7 Access to Exclusive Deals
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Features;
