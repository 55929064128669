import React, { useState } from 'react';
import { Button, CircularProgress } from '@mui/material';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import Infinity from '../../assets/images/infinityLogo.png';

function loadScript(src) {
	return new Promise((resolve) => {
		const script = document.createElement('script')
		script.src = src
		script.onload = () => {
			resolve(true)
		}
		script.onerror = () => {
			resolve(false)
		}
		document.body.appendChild(script)
	})
}

const backendURL = process.env.REACT_APP_BACKEND_URL;

function CheckoutButton({deliveryCharge, totalPrice, clearCart, shippingAddress, shippingName, shippingPhone}) {
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);
  	async function displayRazorpay() {
			setLoading(true);
		if (!shippingAddress || !shippingName || !shippingPhone) {
			toast.error('Please fill in all shipping details before proceeding to checkout.');
			return;
		}
		const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js')

		if (!res) {
      toast.error('Razorpay SDK failed to load. Are you offline?')
			return
		}
		try{
			console.log(totalPrice)
			console.log(`${backendURL}`);
			const data = await fetch(`${backendURL}/razorpay`, { 
				method: 'POST',	
				headers: {
				'Content-Type': 'application/json',
				'totalPrice': `${totalPrice}`, // Replace with the actual total price
				}
			}).then((t) =>
				t.json()
			)
			console.log("fecthcall done")
			console.log(data)
	
			const options = {
				key:process.env.REACT_APP_RAZORPAY_KEY,
				currency: data.currency,
				amount: data.amount,
				order_id: data.id,
				name: 'Infinity-Adda',
				description: 'Thank you for shopping with us. Please come back again!',
				image: Infinity,
				handler: function (response) {
					try{
						clearCart(response.razorpay_payment_id, response.razorpay_order_id, shippingAddress, shippingName, shippingPhone);
						toast.success('Order Placed Successfully! Thank you for shopping with us.')
						navigate('/orders');
					} catch(error) {
						toast.error('Something went wrong. We are sorry :(')
					}
				},
				config: {
					display: {
						blocks: {
							banks: {
								name: 'All payment methods',
								instruments: [
									{
										method: 'upi'
									},
									{
										method: 'card'
									},
									{
											method: 'wallet'
									},
									{
											method: 'netbanking'
									}
								],
							},
						},
						sequence: ['block.banks'],
						preferences: {
							show_default_blocks: true,
						},
					},
				},
			
	
			}
			const paymentObject = new window.Razorpay(options)
			paymentObject.open()
		}
		catch(error){
			console.log(error)
		} finally {
			setLoading(false);
		}
	}
  return (
    <Button variant="contained" color="primary" sx={{ marginTop: 2 }} fullWidth onClick={displayRazorpay}  disabled={loading}>
			{loading ? <CircularProgress size={24} /> : 'Proceed to Checkout'}
    </Button>
  );
}

export default CheckoutButton;
