import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import {Link} from 'react-router-dom';
import { Link as MuiLink} from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Infinity from "../../assets/images/infinityLogo.png";
import { signInWithEmailAndPassword, getAuth } from "firebase/auth";
import { toast } from "react-toastify";
import { useNavigate } from 'react-router-dom';


function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © Infinity Adda '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

// TODO remove, this demo shouldn't need to reset the theme.
export default function SignInSide() {
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const { email, password } = Object.fromEntries(formData);
    try {
      const auth = getAuth();
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      if (userCredential.user) {
        toast.success(" Welcome back 💜 ");
        navigate("/");
      }
    } catch (error) {
      toast.error(" User credentials mismatch ");
    }
  };

  return (
    // <ThemeProvider theme={defaultTheme}>
      <Grid container component="main" sx={{ height: "100vh", my:"auto"}}>
        <CssBaseline />

        <Grid container  
            sx={{
              maxWidth: 400,
              margin:"auto",
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
 
            }}
        >
          <Box
            sx={{
              // border: '1px solid grey',
              borderRadius: '10px',
              boxShadow: 24,
              padding: 4,
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: 'text.primary' , margin: "auto"}}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5" align='center'>
              Sign In
            </Typography>
            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
              />
              <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label="Remember me"
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Sign In
              </Button>
              <Grid container>
                <Grid item xs>
                  <Link to="/forgotPassword" variant="body2" style={{fontSize: "0.8em"}}>
                    <MuiLink>
                      Forgot password?
                    </MuiLink>
                  </Link>
                </Grid>
                <Grid item>
                  <Link to="/signup" variant="body2" style={{fontSize: "0.8em"}}>
                    <MuiLink>
                      {"Don't have an account? Sign Up"}  
                    </MuiLink>
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Grid>
      </Grid>
    // </ThemeProvider>
  );
}