import React, { useState } from 'react';
import { getAuth } from "firebase/auth";
import { app } from "../../firebase"
import { Grid, CssBaseline, Typography, Tab, Tabs } from '@mui/material';
import ManageOrders from './manageOrders/manageOrders';
import ManageProducts from './manageProducts/manageProducts';
import { useAuthState } from "react-firebase-hooks/auth";

const auth = getAuth(app);

function Admin() {
  const [currentTab, setCurrentTab] = useState(0);
  const [user] = useAuthState(auth);
  const handleChangeTab = (event, newValue) => {
    setCurrentTab(newValue);
  };
  const adminEmail = process.env.REACT_APP_ADMIN_EMAIL;
  if (!user || (user && user.email !== adminEmail)) {
    return (
      <Grid container component="main" sx={{ marginTop: '5vh', maxWidth: '1000px', marginX: 'auto' }}>
        <CssBaseline />
        <Grid item xs={12} sm={12} md={12} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Typography component="h1" variant="h5">
            You are not authorized to access this page !
          </Typography>
        </Grid>
      </Grid>
    )
  }
  return (
    <Grid container component="main" sx={{ marginTop: '5vh', maxWidth: '1000px', marginX: 'auto' }}>
      <CssBaseline />
      <Grid item xs={12} sm={12} md={12} sx={{ 
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'center', 
        backgroundColor: 'white',
        borderRadius: "5px",
        paddingTop: "0px",
        }}>
        <Typography component="h1" variant="h5">
          ADMIN PANEL
        </Typography>
        <Tabs value={currentTab} onChange={handleChangeTab} centered sx={{marginBottom: "20px"}}>
          <Tab label="Manage Products" />
          <Tab label="Manage Orders" />
        </Tabs>
        {currentTab === 0 && (
          <div>
            <ManageProducts />
          </div>
        )}
        {currentTab === 1 && (
          <div>
            <ManageOrders />
          </div>
        )}
      </Grid>
    </Grid>
  );
}

export default Admin;
