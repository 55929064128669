// ForgotPassword.js
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Link as MuiLink, Box, Typography, TextField, Button, Grid } from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Infinity from "../../assets/images/infinityLogo.png";
import Avatar from '@mui/material/Avatar';
import CssBaseline from '@mui/material/CssBaseline';
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { toast } from "react-toastify";


const ForgotPassword = () => {
  const handleSubmit = (event) => {
    event.preventDefault();
    // Add your logic for handling the forgot password form submission
    const formData = new FormData(event.currentTarget);
    const { email } = Object.fromEntries(formData);
    const auth = getAuth();
    sendPasswordResetEmail(auth, email)
      .then(() => {
        toast.success("Password reset link sent to your email address")
      })
      .catch((error) => {
        toast.error("Error sending password reset email")
      });
      
  };

  return (
    <Grid container component="main" sx={{ height: '90vh', marginTop:'10vh'}}>
      <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: `url(${Infinity})`,
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) =>
              t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
        <Grid item xs={12} sm={8} md={5} square >
        <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Forgot Password
          </Typography>
          <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Reset Password
            </Button>
            <Grid container>
              <Grid item xs>
                <MuiLink component={RouterLink} to="/signin" variant="body2">
                  Back to Sign In
                </MuiLink>
              </Grid>
            </Grid>
          </Box>
        </Box>
        </Grid>
    </Grid>
  );
};

export default ForgotPassword;
