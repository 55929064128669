import React from 'react';
import { Typography } from '@mui/material';

function ShippingPolicy() {
  return (
    <div>
      <hr />
      <Typography>
        Welcome to InfinityAdda. This Shipping Policy outlines the terms and conditions related to the shipping of products ordered through our website.
      </Typography>
      <br />

      <Typography fontWeight="bold">
        1. Shipping Methods
      </Typography>
      <Typography>
        InfinityAdda offers various shipping methods, including standard, express, and any other methods deemed appropriate. The available options will be presented during the checkout process.
      </Typography>
      <br />

      <Typography fontWeight="bold">
        2. Shipping Costs
      </Typography>
      <Typography>
        Shipping costs, if applicable, will be clearly communicated to you before you complete your purchase. Any additional fees, such as customs duties, will also be outlined.
      </Typography>
      <br />

      <Typography fontWeight="bold">
        3. Shipping Times
      </Typography>
      <Typography>
        Estimated shipping times will be provided based on the selected shipping method and your location. While we strive to meet these estimates, actual delivery times may vary. Orders are processed within 1-2 business days (excluding weekends and holidays) after receiving payment.Estimated delivery within 3-10 business days from the date of shipment.The total time from placing an order to receiving the package is a combination of processing time and shipping time.
      </Typography>
      <br />

      <Typography fontWeight="bold">
        4. Order Processing
      </Typography>
      <Typography>
        Orders will be processed and shipped within the timeframe specified on the product page or during the checkout process. Delays may occur due to factors beyond our control, and we will make efforts to communicate any delays promptly.
      </Typography>
      <br />

      <Typography fontWeight="bold">
        5. Tracking Information
      </Typography>
      <Typography>
        Once your order is shipped, you will receive tracking information to monitor the status and location of your package, if applicable.
      </Typography>
      <br />

      <Typography fontWeight="bold">
        6. Changes to Shipping Policy
      </Typography>
      <Typography>
        InfinityAdda reserves the right to modify or update this Shipping Policy at any time. Changes will be effective immediately upon posting to the website.
      </Typography>
    </div>
  );
}

export default ShippingPolicy;
