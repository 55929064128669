import React, { useState, useEffect } from 'react';
import { Grid, CssBaseline, Typography, LinearProgress, CircularProgress } from '@mui/material';
import { getDocs, collection } from 'firebase/firestore';
import {Link} from 'react-router-dom';
import { db } from '../../firebase';
import Product from './components/product';
 
function Products() {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'Products'));

        const productsData = [];
        querySnapshot.forEach((doc) => {
          productsData.push({
            id: doc.id,
            ...doc.data(),
          });
        });

        setProducts(productsData);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching products:', error.message);
        setLoading(false);
      }
    };

    fetchProducts();
  }, []);


  return (
    <div>
      <Grid item xs={12} sm={10} md={10} lg={8} sx={{ mx: 'auto', textAlign: "justify", marginTop: "5vh"}}>
      <CssBaseline />
        {loading ? (
          <div style={{width:"300px",  margin: "auto"}}>
            <LinearProgress />
          </div>
        ) : products && products.length > 0 ? (
          <Grid container spacing={2} mt={2}>
            {window.innerWidth < 100 ? (
              products.map((product) => (
                <Link key={product.id} to={`/products/${product.id}`} style={{ textDecoration: 'none' }}>
                  renderMobileProduct(product)
                </Link>
                ))
            ) : (
              products.map((product) => (
                <Product key={product.id} product={product}  />
              ))
            )}
          </Grid>
        ) : (
          <Typography variant="body1" component="div">
            No products found.
          </Typography>
        )}
      </Grid>

    </div>
  );
}

export default Products;