import React from 'react';
import { Typography } from '@mui/material';

function ProductPrice({ product, isMobile }) {
  return (
    <div style={{paddingLeft:  isMobile? '0px' : '20px', paddingRight:  isMobile? '0px' : '20px', marginBottom: "10px"}}>
      <hr />
      <Typography
        variant="h4"
        component="div"
        mt={2}
        style={{ fontWeight: "bolder", fontSize: isMobile? "1em": "1.2em"}}
      >
        {product.productName}
      </Typography>

      <hr />
      <Typography variant="h6" component="div" style={{ fontSize: isMobile? "1em": "1.2em" }}>
        <span>
          {product.maxPrice ? (
            <span>
              <strike>&#x20B9; {product.maxPrice}</strike> &nbsp;
            </span>
          ) : (
            <span>
              <strike>&#x20B9; {product.price}</strike> &nbsp;
            </span>
          )}
        </span>
        <b>&#x20B9; {product.price} &nbsp;
          </b>
        <span style={{ backgroundColor: "#7A1B1C", paddingX: "10px", borderRadius: "10px"}}>
          {product.maxPrice ? (
            <span style={{ fontSize: "0.8em", color: "white" }}>
              &nbsp;{" "}
              {(
                ((product.maxPrice - product.price) / product.maxPrice) *
                100
              ).toFixed(0)}
              % Off &nbsp;
            </span>
          ) : (
            <span style={{ fontSize: "0.8em", color: "white" }}>
              &nbsp; 0% Off &nbsp;
            </span>
          )}
        </span>
      </Typography>
    </div>
  );
}

export default ProductPrice;
