import React, { useState, useEffect } from 'react';
import { Grid, CssBaseline, Typography, LinearProgress } from '@mui/material';
import { getDocs, collection } from 'firebase/firestore';
import { db } from '../../../firebase';
import Product from '../../Products/components/product';
import categories from '../../../assets/Categories/categories'; 

function Products() {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'Products'));

        const productsData = [];
        querySnapshot.forEach((doc) => {
          const productData = {
            id: doc.id,
            ...doc.data(),
          };
          productsData.push(productData);
        });

        setProducts(productsData);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching products:', error.message);
        setLoading(false);
      }
    };

    fetchProducts();
  }, []);

  const renderProductsByCategory = (category) => {
    const filteredProducts = products.filter(product => product.category === category.value);
    return (
      <Grid container spacing={2} mt={2}>
        {filteredProducts.map((product) => (
          <Product key={product.id} product={product} />
        ))}
      </Grid>
    );
  };

  return (
    <div>
      {loading ? (
        <div style={{ margin:"auto"}}>
          <LinearProgress  style={{ width: "100%", margin: "auto" }} />
        </div>
      ) : (
        <div>
          {categories.map((category) => {
            const productsInCategory = products.filter(product => product.category === category.value);
            if (productsInCategory.length > 2) {
              return (
                <div key={category.value}>
                    <Typography variant="h6" gutterBottom sx={{ 
                      marginTop: '35px', 
                      background: "rgb(87,33,33)",
                      background: "linear-gradient(90deg, rgba(31,10,10,1) 19%, rgba(59,39,80,1) 100%)",
                      padding: '10px', 
                      borderRadius: '5px', 
                      color: "white",
                      fontWeight: "bold",
                      fontSize: "14px",
                      marginBottom: '0px',
                      paddingX: "30px",
                      boxShadow: "rgb(38, 57, 77) 0px 20px 30px -10px",
                    }}>
                      <div data-aos="zoom-in">
                        {category.label}
                      </div>
                    </Typography>
                  {renderProductsByCategory(category)}
                </div>
              );
            }
            return null;
          })}
          {products.length === 0 && (
            <Typography variant="body1" component="div">
              No products found.
            </Typography>
          )}
        </div>
      )}
    </div>
  );
}

export default Products;
