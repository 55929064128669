import {
  Button,
  ListItemButton,
  ListItemText,
  Toolbar,
  styled,
} from "@mui/material";


export const colors = {
  textColor: "#FFFFFF",
  bgColors: "#000000",
  color1: "#000000"
};

export const NavToolbar = styled(Toolbar)(() => ({
  display: "flex",
  flexFlow: "row",
  justifyContent: "space-between",
  alignItems: "center"
}));

export const pages = [
  { name: "Home", link: "home" },
  { name: "Products", link: "products" },
  { name: "About", link: "about" },
];

export const MenuButton = styled(Button)(() => ({
  color: colors.textColor,
  fontSize: "15px",
  // fontWeight: "600",
  position: 'relative', // Add position relative to create a reference point for absolute positioning of underline
  textDecoration: 'none',
  transition: 'all 0.3s', // Ensuring no default text decoration for the button
  "&:hover": {
    color: "#FF8080",
    transform: 'translateY(-2px)',
    "&::before": {
      content: '""',
      position: 'absolute',
      display: 'block',
      width: '100%',
      height: '2px',
      bottom: '0',
      left: '0',
      backgroundColor: '#FFF',
      transform: 'scaleX(0)',
      transition: 'all 0.3s ease',
    }
  },
  "&:hover::before": { // Pseudo-element for hover state
    transform: 'scaleX(1)', // Making the underline visible on hover
  }
}));

export const MenuListItemButton = styled(ListItemButton)(() => ({
  // borderBottom: "1px solid",
  alignItems: "center",
  textAlign: "center",

}));

export const MenuListItemText = styled(ListItemText)(() => ({
  fontSize: "16px",
  fontWeight: "500"
}));

//Skill Section
