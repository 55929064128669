import { useState, useMemo, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import "./App.css";
import Navbar from "./Components/Navbar";
import Footer from "./Components/Footer";
import Home from "./Pages/Home";
import Signin from "./Pages/SignIn";
import Signup from "./Pages/SignUp";
import About from "./Pages/About";
import Products from "./Pages/Products";
import Profile from "./Pages/Profile";
import Cart from "./Pages/Cart";
import Orders from "./Pages/Orders";
import Admin from "./Pages/Admin";
import Policies from "./Pages/Policies";
import NotFound from "./Pages/NotFound";
import FilterResults from "./Pages/FilterResults";
import SearchResults from "./Pages/SearchResults";
import ProductDetail from "./Pages/ProductDetail";
import ForgotPassword from "./Pages/ForgotPassword";
import { Container, Box } from "@mui/material";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness7Icon from "@mui/icons-material/Brightness7";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import IconButton from "@mui/material/IconButton";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { getDatabase, ref, set } from "firebase/database";
import { app } from "./firebase";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AOS from "aos";
import "aos/dist/aos.css";
const db = getDatabase(app);

function App() {
  const [mode, setMode] = useState("light");

  const customPrimaryColor = "#291423";

  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: mode,
          primary: {
            main: customPrimaryColor,
          },
        },
      }),
    [mode, customPrimaryColor]
  );
  useEffect(() => {
    AOS.init({
      duration: 700,
    });
    AOS.refresh();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <div className={`App ${theme.palette.mode}`}>
        <Router>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              minHeight: "100vh",
            }}
          >
            <Navbar />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/home" element={<Home />} />
              <Route
                path="/about"
                element={
                  <Box
                    sx={{ flex: 1, marginTop: "80px", marginBottom: "50px" }}
                  >
                    <Policies />
                  </Box>
                }
              />
              <Route
                path="/admin"
                element={
                  <Box
                    sx={{ flex: 1, marginTop: "80px", marginBottom: "50px" }}
                  >
                    <Admin />
                  </Box>
                }
              />
              <Route path="/signup" element={<Signup />} />
              <Route path="/signin" element={<Signin />} />
              <Route
                path="/products"
                element={
                  <Box
                    sx={{ flex: 1, marginTop: "80px", marginBottom: "50px" }}
                  >
                    <Products />
                  </Box>
                }
              />
              <Route
                path="/profile"
                element={
                  <Box
                    sx={{ flex: 1, marginTop: "80px", marginBottom: "50px" }}
                  >
                    <Profile />
                  </Box>
                }
              />
              <Route
                path="/cart"
                element={
                  <Box
                    sx={{ flex: 1, marginTop: "80px", marginBottom: "50px" }}
                  >
                    <Cart />
                  </Box>
                }
              />
              <Route
                path="/policies"
                element={
                  <Box
                    sx={{ flex: 1, marginTop: "80px", marginBottom: "50px" }}
                  >
                    <Policies />
                  </Box>
                }
              />
              <Route
                path="/orders"
                element={
                  <Box
                    sx={{ flex: 1, marginTop: "80px", marginBottom: "50px" }}
                  >
                    <Orders />
                  </Box>
                }
              />
              <Route
                path="/products/:productId"
                element={
                  <Box
                    sx={{ flex: 1, marginTop: "80px", marginBottom: "50px" }}
                  >
                    <ProductDetail />
                  </Box>
                }
              />
              <Route
                path="/forgotPassword"
                element={
                  <Box
                    sx={{ flex: 1, marginTop: "80px", marginBottom: "50px" }}
                  >
                    <ForgotPassword />
                  </Box>
                }
              />

              <Route
                path="/search/:query"
                element={
                  <Box
                    sx={{ flex: 1, marginTop: "80px", marginBottom: "50px" }}
                  >
                    <SearchResults />
                  </Box>
                }
              />
              <Route
                path="/category/:query"
                element={
                  <Box
                    sx={{ flex: 1, marginTop: "80px", marginBottom: "50px" }}
                  >
                    <FilterResults />
                  </Box>
                }
              />
              <Route
                path="*"
                element={
                  <Box
                    sx={{ flex: 1, marginTop: "80px", marginBottom: "50px" }}
                  >
                    <NotFound />
                  </Box>
                }
              />
            </Routes>
            <Footer />
            <div
              style={{
                position: "fixed",
                bottom: "20px",
                right: "20px",
                borderRadius: "50%",
                backgroundColor: "#25D366",
              }}
            >
              <a href="https://wa.me/7879070809">
                <WhatsAppIcon
                  className="whatsapp-icon"
                  sx={{
                    color: "white",
                    fontSize: 50,
                    padding: "8px",
                    transition: "0.3s",
                    "&:hover": {
                      backgroundColor: "#7A1B1C", 
                      borderRadius: "50%",
                    },
                  }}
                />
              </a>
            </div>
          </div>
        </Router>
        <ToastContainer position="top-right" autoClose={5000} />
      </div>
    </ThemeProvider>
  );
}

export default App;
