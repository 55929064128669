import React from 'react';
import { Typography } from '@mui/material';

function TermsAndConditions() {
  return (
    <div>
      <hr />
      <Typography>
        Welcome to InfinityAdda. By using our website, you agree to comply with and be bound by the following terms and conditions.
      </Typography>
      <br />

      <Typography fontWeight="bold">
        1. Acceptance of Terms
      </Typography>
      <Typography>
        By accessing and using InfinityAdda, you acknowledge that you have read, understood, and agree to these terms. If you do not agree with any part of these terms, please do not use our website.
      </Typography>
      <br />

      <Typography fontWeight="bold">
        2. User Conduct
      </Typography>
      <Typography>
        You agree to use InfinityAdda for lawful purposes and in a way that does not infringe on the rights of others or restrict or inhibit anyone else's use of the site.
      </Typography>
      <br />

      <Typography fontWeight="bold">
        3. Intellectual Property
      </Typography>
      <Typography>
        The content on InfinityAdda, including text, graphics, logos, and images, is protected by copyright and other intellectual property laws. You may not reproduce, distribute, or republish any content without our prior written consent.
      </Typography>
      <br />

      <Typography fontWeight="bold">
        4. Privacy Policy
      </Typography>
      <Typography>
        Our Privacy Policy governs the collection, use, and disclosure of your personal information. By using InfinityAdda, you consent to the terms outlined in our Privacy Policy.
      </Typography>
      <br />

      <Typography fontWeight="bold">
        5. Limitation of Liability
      </Typography>
      <Typography>
        InfinityAdda and its affiliates shall not be liable for any direct, indirect, incidental, special, or consequential damages arising out of the use or inability to use the website.
      </Typography>
      <br />

      <Typography fontWeight="bold">
        6. Changes to Terms
      </Typography>
      <Typography>
        InfinityAdda reserves the right to modify or update these terms and conditions at any time. Changes will be effective immediately upon posting to the website.
      </Typography>
    </div>
  );
}

export default TermsAndConditions;
